import { Row, Col, Space } from "antd";
import { BASE_URL } from "utils/constants";

const ResourceCard = (props) => {
  // Props
  const { item } = props;

  return (
    <Row>
      <Col span={24} className="overview-resourceCardColumn">
        <div className="overview-resourceContainer">
          <div className="overview-resourceInfoContainer">
            <Space>
              <img
                className="overview-resourceImage"
                src={
                  item?.resource_details?.photo
                    ? `${BASE_URL}/${item.resource_details.photo}`
                    : "/assets/icons/user_image_1.png"
                }
                alt="Resource"
              />
              <div className="overview-resourceDetailsContainer">
                <div className="overview-resourceName">
                  {item?.resource_details?.name || "N/A"}
                </div>
                <div className="overview-resourcePositionName">
                  {item?.resource_details?.resource_type_details?.name || ""}
                </div>
              </div>
            </Space>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ResourceCard;

import moment from 'moment';

export const secondsToTime = (duration) => {
    let seconds = duration;
    let minutes = seconds / 60;
    let hours = minutes / 60;

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    const resultObject = {
        hours: parseFloat(hours).toFixed(2),
        minutes: parseInt(minutes),
        seconds: parseInt(seconds),
    };

    return resultObject;
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
    let foundDates = [];

    while (endDate.isSameOrBefore(startDate)) {
        foundDates.push(`${endDate.format("YYYY-MM-DD")}`);
        endDate.add(1, "days");
    }
    return foundDates;
};

export const getCommonDatesData = (data, results, convertTime) => {
    if (results) {
        let commonDatesValueArray = data?.filter((item) =>
            results.some((resultItem) => {
                if (resultItem === item.name) {
                    let matchedItemObject = item;
                    let dateObject = moment(matchedItemObject.name, 'YYYY-MM-DD');
                    
                    if(convertTime) {
                        matchedItemObject.value = secondsToTime(
                            matchedItemObject.value
                        ).hours;
                    }
                    
                    matchedItemObject.name = moment(dateObject).format('dddd').substring(0,3);
                    return matchedItemObject;
                }
                else {
                    return null;
                }
            })
        );

        if (commonDatesValueArray) {
            return commonDatesValueArray;
        } else {
            return null;
        }
    }
};

export const formatDate = (dateValue, formatOn) => {
    if (dateValue) {
        if (formatOn) {
            return moment(dateValue).format("YYYY-MM-DD hh:mm:ss");
        } else {
            return moment(dateValue);
        }
    } else {
        return null;
    }
};

export const renderContentTitle = (stepId, projectDetailsData) => {
    switch(stepId) {
        case 0:
            return (projectDetailsData ? "Project Details" : "Create New Project");
        case 1:
            return 'Milestone Configuration';
        case 2:
            return 'Checklist';
        case 3:
            return 'Resources/Members';
        default:
            return null;
    }
}

export const renderSecondaryButtonTitle = (stepId) => {
    switch(stepId) {
        case 0:
            return null;
        case 1:
            return 'Back';
        case 2:
            return 'Back';
        case 3:
            return 'Back';
        default:
            return null;
    }
}

export const renderPrimaryButtonTitle = (stepId, projectDetailsData, createdProjectId) => {
    switch(stepId) {
        case 0:
            return ((projectDetailsData || createdProjectId) ? "Edit & Next Step" : "Create Project & Next Step");
        case 1:
            return 'Next Step';
        case 2:
            return 'Next Step';
        case 3:
            return 'Go To Projects';
        default:
            return null;
    }
}

export const getResourceByType = (resoureArray , type) => {
    const foundResource = resoureArray?.find((resource, index) => resource?.resource_details?.resource_type_details?.name === type);
    return foundResource;
}

export const numberWithCommas = (data) => {
    if(!data) {
        return null;
    }

    if(typeof data !== "string") {
        return data.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    else {
        return data.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const detectDeadline = (date) => {
    if(!date) return 'no date';
    if(moment(date, "YYYY/MM/DD").isBefore(moment().format("YYYY/MM/DD"))) return 'over';
    if(moment(date, "YYYY/MM/DD").isSame(moment().format("YYYY/MM/DD"))) return 'today';
    if(moment(date, "YYYY/MM/DD").isAfter(moment().format("YYYY/MM/DD"))) return 'future';
}

export const getDocumentName = (code) => {
    switch(code) {
        case "srs":
            return "SRS";
        case "brd":
            return "BRD";
        case "estimation":
            return "Estimation";
        case "architecture":
            return "Architecture";
        case "database_design":
            return "Database Design";
        default:
            return "N/A";
    }
}

export const generateEngagementDates = (resource_projects) => {
    let project_dates = [];

    if(resource_projects && resource_projects?.length>0) {
        resource_projects?.forEach((item, index) => {
            item?.engagement_history?.engaged_from && project_dates.push(item?.engagement_history?.engaged_from);
            item?.engagement_history?.engaged_to && project_dates.push(item?.engagement_history?.engaged_to);
        });
    }

    if(Array.isArray(project_dates) && project_dates?.length>0) {
        const momentedDates = project_dates?.map((date) => moment(date));
        const maxDate = moment.max(momentedDates);
        const minDate = moment.min(momentedDates);

        const startFromDate = minDate ? moment(minDate).format("DD MMM YYYY") : null;
        const willFreeDate = maxDate ? moment(maxDate).format("DD MMM YYYY") : null;
        
        const engagementDates = {
            startFromDate: startFromDate,
            willFreeDate: willFreeDate,
        }

        return engagementDates;
    }
    else {
        return null;
    }
}

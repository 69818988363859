import { Collapse } from "antd";

const { Panel } = Collapse;

const CommonPanel = (props) => {
    // Props
    const {
        defaultActiveKey,
        headerText,
        panelStyle,
        collapseStyle,
        children,
        filterQueryName,
    } = props;

    const setDefaultActiveKey = () => {
        if (defaultActiveKey) {
            return [defaultActiveKey];
        } else {
            let localStorageFilter = localStorage.getItem(filterQueryName);

            // If data found in localStorage then do not collapse
            if (localStorageFilter) {
                return ["1"];
            } else {
                return [];
            }
        }
    };


    return (
        <Collapse 
            defaultActiveKey={setDefaultActiveKey()}
            style={collapseStyle}
        >
            <Panel
                header={headerText}
                key="1"
                style={panelStyle}
            >
                {children}
            </Panel>
        </Collapse>
    );
}
 
export default CommonPanel;
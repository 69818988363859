import { useState, useEffect, useContext } from "react";
import { Col, Row, Divider, notification } from "antd";
import "./DashboardComponent.scss";
import { GET_DASHBOARD_SUMMARY } from "utils/api";
import { postData, getData } from "utils/api-service";
import {
  ChartComponent,
  ProjectComponent,
  ResourceComponent,
} from "components";
import moment from "moment";
import {
  enumerateDaysBetweenDates,
  getCommonDatesData,
  secondsToTime,
} from "utils/functions";

const DashboardComponent = () => {
  // States
  const [loader, setLoader] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [logHourData, setLogHourData] = useState(null);
  const [projectValueData, setProjectValueData] = useState(null);
  const [topLogProjects, setTopLogProjects] = useState(null);
  const [convertedWorkLogData, setConvertedWorkLogData] = useState(null);
  const [convertedProjectValueData, setConvertedProjectValueData] =
    useState(null);
  const [convertedTopLogData, setConvertedTopLogData] = useState(null);

  const getLastWeekData = (allData, convertTime) => {
    const fromDate = moment();
    const toDate = moment().subtract(6, "days");
    // const fromDate = moment("2022-08-30", "YYYY-MM-DD");
    // const toDate = moment(
    //     moment("2022-08-30", "YYYY-MM-DD")
    //         .subtract(6, "days")
    //         .format("YYYY-MM-DD")
    // );

    const datesDifferenceArray = enumerateDaysBetweenDates(fromDate, toDate);

    const existedData = getCommonDatesData(
      allData,
      datesDifferenceArray,
      convertTime
    );
    const weeklyDataObject = { data: existedData ? existedData : null };
    const totalData =
      existedData &&
      existedData.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue?.value);
      }, 0);
    weeklyDataObject.total_data = totalData ? totalData.toFixed(2) : 0;

    return weeklyDataObject;
  };

  const convertValuesToHours = (allData) => {
    const convertedDataArray =
      allData &&
      allData?.map((item, index) => {
        let itemCopy = item;

        if (itemCopy?.value) {
          const convertedTimeObject = secondsToTime(itemCopy?.value);
          itemCopy.value = parseInt(convertedTimeObject?.hours) || parseInt(itemCopy.value);
        }

        return item;
      });

    return convertedDataArray;
  };

  useEffect(() => {
    if (logHourData) {
      const lastWeekData = getLastWeekData(logHourData?.data, true);
      
      if (lastWeekData) {
        setConvertedWorkLogData(lastWeekData);
      }
    }

    if (projectValueData) {
      const lastWeekData = getLastWeekData(projectValueData?.data, false);

      if (lastWeekData) {
        setConvertedProjectValueData(lastWeekData);
      }
    }

    if (topLogProjects) {
      const convertedData = convertValuesToHours(topLogProjects?.data);
      if (convertedData) {
        setConvertedTopLogData(convertedData);
      }
    }
  }, [logHourData, projectValueData, topLogProjects]);

  const fetchData = async () => {
    let query = GET_DASHBOARD_SUMMARY();
    let response = await getData(query);
    if (response && response.code === 200) {
      setDashboardData(response?.data);
      setLogHourData(response?.data?.workLogs);
      setProjectValueData(response?.data?.projectValue);
      setTopLogProjects(response?.data?.topTenLogProjects);
      setLoader(false);
    } else {
      notification.error({
        message: "Error Getting Dashboard Data",
        placement: "topLeft",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <ChartComponent
            data={dashboardData?.project_status?.data || null}
            totalData={dashboardData?.project_status?.total_data || null}
            headLine="Total Projects"
            chartType="pie"
            timeLine="Lifetime"
            chartName="projects_chart"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <ChartComponent
            data={dashboardData?.engaged_resource?.data || null}
            totalData={dashboardData?.engaged_resource?.total_data || null}
            headLine="Resource Engagement"
            chartType="pie"
            timeLine="Current"
            chartName="resources_chart"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <ChartComponent
            data={convertedWorkLogData?.data || null}
            totalData={convertedWorkLogData?.total_data || null}
            headLine="Log Hour"
            chartType="column"
            timeLine="Last Week"
            chartName="loghour_chart"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <ChartComponent
            data={convertedProjectValueData?.data || null}
            totalData={convertedProjectValueData?.total_data || null}
            headLine="Costing"
            chartType="column"
            timeLine="Last Week"
            chartName="costing_chart"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        <Col xs={24} sm={24} md={24}>
          <ChartComponent
            data={convertedTopLogData || null}
            totalData={dashboardData?.topTenLogProjects?.total_data || null}
            headLine="Top 10 most work log projects"
            chartType="column"
            timeLine="Top 10 work"
            hideTotalCount
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
        <Col xs={24} sm={24} md={24}>
          <ResourceComponent
            restrictActions={true}
            contentTitle="Resource Engagement"
            comingFrom="dashboard"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
        <Col xs={24} sm={24} md={24}>
          <ProjectComponent restrictActions={true} contentTitle="Project List" />
        </Col>
      </Row>
    </>
  );
};

export default DashboardComponent;

import { useState, useEffect, useContext, useCallback } from "react";
import { ContentHeader } from "components";
import {
  Row,
  Col,
  Space,
  Card,
  Typography,
  Button,
  Form,
  notification,
  Popconfirm,
  Table,
  Tag,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Tooltip,
  Drawer,
  Image,
  Modal,
  Progress,
  Popover,
  Timeline,
} from "antd";
import {
  DeleteOutlined,
  FormOutlined,
  ZoomOutOutlined,
  CalendarOutlined,
  RadarChartOutlined,
  ZoomInOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./ProjectComponent.scss";
import {
  PROJECT_CREATE,
  PROJECTS_LIST,
  PROJECTS_DELETE,
  MILESTONE_STATUS_LIST,
  PROJECT_RESOURCES_DELETE,
  PROJECT_RESOURCES_GET,
  PROJECT_MILESTONE_UPDATE,
  PROJECT_MILESTONE_NOTE_CREATE,
} from "../../utils/api";
import { postData, getData } from "../../utils/api-service";
import {
  getResourceByType,
  secondsToTime,
  detectDeadline,
  formatDate,
} from "utils/functions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CommonPanel from "../../components/common/CommonPanel";
import { BASE_URL } from "../../utils/constants";
import { ThemeContext } from "context";

const ProjectComponent = (props) => {
  // Props
  const { setParentCurrentPage, restrictActions, contentTitle } = props;

  // States
  const [flag, setFlag] = useState("");
  const [rowData, setRowData] = useState(null);
  const [milestoneRowData, setMilestoneRowData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [projects, setProjects] = useState();
  const [refresh, setRefresh] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [milestoneStatus, setMilestoneStatus] = useState();
  const [filterQueryData, setFilterQueryData] = useState({});
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [milestoneDetailsVisible, setMIlestoneDetailsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [milestoneModalData, setMilestoneModalData] = useState(null);
  const [page, setPage] = useState(1);
  // Antd Constants
  const [projectForm] = Form.useForm();
  const [milestoneNoteForm] = Form.useForm();

  const { Title, Text } = Typography;
  const { Option } = Select;
  const { confirm } = Modal;
  const { RangePicker } = DatePicker;

  console.log("rowData", rowData);

  // Router
  const navigate = useNavigate();

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  // Context
  const { themeSettings } = useContext(ThemeContext);

  const onChange = (value) => {};

  const onSearch = (value) => {};

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const showDrawer = (obj) => {
    setDetailsVisible(true);
  };
  const showMilestoneDrawer = () => {
    setMIlestoneDetailsVisible(true);
  };

  const onClose = () => {
    setDetailsVisible(false);
  };
  const onMilestoneClose = () => {
    setMIlestoneDetailsVisible(false);
  };

  const showModal = (modalName) => {
    setOpen({ openedModal: modalName });
  };
  const hideModal = () => {
    setModalData(null);
    setMilestoneModalData(null);
    setOpen(false);
    milestoneNoteForm.setFieldValue("new_milestone_note", null);
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let milestonequery = MILESTONE_STATUS_LIST();
      let milestoneesponse = await getData(milestonequery);
      if (milestoneesponse && milestoneesponse.code === 200) {
        setMilestoneStatus(milestoneesponse.data.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let select_parent = document.getElementsByClassName(
      "milestone_status_select"
    );

    if (select_parent.length > 0) {
      for (var i = 0; i < select_parent.length; i++) {
        let select_selector = select_parent[i].getElementsByClassName(
          "ant-select-selector"
        );
        select_selector[0].style.backgroundColor =
          milestoneRowData[i]?.milestone_status?.color_code;
      }
    }
  }, [milestoneRowData]);

  const onFinish = (values) => {
    // console.log("Onfinish values: ", values);

    let filterQueryObject = {
      ...(values?.name && {
        name: values.name,
      }),
      ...(values?.status && {
        status: values.status,
      }),
      ...(values?.pmo && {
        pmo: values.pmo,
      }),

      ...(values?.dates && {
        start_date: values?.dates[1].format("YYYY-MM-DD HH:mm:ss"),
      }),
      ...(values?.dates && {
        end_date: values?.dates[0].format("YYYY-MM-DD HH:mm:ss"),
      }),
    };
    setFilterQueryData(filterQueryObject);
    refetch();
  };

  const toggleCustomerDetailsDrawer = () => {
    setDetailsVisible(!detailsVisible);
  };

  const filterReset = () => {
    projectForm.resetFields();
    setFilterQueryData({});
    refetch();
  };

  const handleDelete = async (e) => {
    let query = PROJECTS_DELETE() + "/" + e.variables.deleteTypeId;
    let response = await postData(query);
    if (response && response.code === 200) {
      notification.success({
        message: "Success",
        description: `${"Project Deleted Successfully"}`,
        placement: "topLeft",
      });
      setLoader(false);
    } else {
      notification.error({
        message: "Error",
        placement: "topLeft",
      });
    }

    refetch();
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let query = PROJECTS_LIST();
      let response = await getData(query, filterQueryData);

      if (response && response.code === 200) {
        setProjects(response.data.data);
        setPageCount("pageCount", response?.data?.total);
        setLoader(false);
      } else {
        notification.error({
          message: "Error getting data",
          placement: "topLeft",
        });

        setLoader(false);
      }
    };

    // call the function
    fetchData();

    // make sure to catch any error
  }, [refresh]);

  const deleteConfirm = (id, projectID, deleteFunction) => {
    confirm({
      title: "Are you sure you want to remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteFunction(id, projectID);
      },

      onCancel() {
        return null;
      },
    });
  };

  const setCurrentPageToStates = (page) => {
    setCurrentPage(page);

    if (setParentCurrentPage) {
      setParentCurrentPage(page);
    }
  };

  const paginate = (page) => {
    setCurrentPageToStates(page);
  };

  const updateProjectMilestone = async (dataObject) => {
    const { project_id, milestone_id, status_id, start_date, end_date } =
      dataObject;
    const customQuery = PROJECT_MILESTONE_UPDATE();
    const formObject = {
      ...(project_id && { project_id: project_id }),
      ...(milestone_id && { milestone_id: milestone_id }),
      ...(status_id && { status_id: status_id }),
      ...(start_date && { start_date: start_date }),
      ...(end_date && { end_date: end_date }),
    };
    const response = await postData(customQuery, formObject);

    if (response && response.code === 201) {
      notification.success({
        message: "Success",
        description: `Milestone Updated Successfully`,
        placement: "topLeft",
      });
      onMilestoneClose();
      refetch();
    } else {
      notification.error({
        message: "Error",
        description: `Error Updating Milestone`,
        placement: "topLeft",
      });
    }
  };

  const removeResourceFromProject = async (resourceID, projectID) => {
    const deleteQuery = PROJECT_RESOURCES_DELETE();
    const currentProjectId = projectID;
    const customQuery = `${deleteQuery}`;
    const formObject = {
      projects_id: currentProjectId,
      resources_id: resourceID,
    };
    const response = await postData(customQuery, formObject);

    if (response && response.code === 201) {
      notification.success({
        message: "Success",
        description: `Resource Removed Successfully`,
        placement: "topLeft",
      });
      refetch();
      setOpen(false);
    } else {
      notification.error({
        message: "Error",
        description: `Error Removing Resource`,
        placement: "topLeft",
      });
    }
  };

  const calculateMilestonePercent = (milestoneList) => {
    let totalMilestone = milestoneList?.length;
    let count = 0;

    milestoneList?.map((milestone) => {
      if (milestone.milestone_status.name === "Complete") {
        count = count + 1;
      }
    });

    return Math.round((100 * count) / totalMilestone);
  };

  const sizeChange = (currentPage, size) => {
    setCurrentPageToStates(currentPage);
    setPerPageCount(size);
  };

  const detectColor = (deadlineStatus) => {
    switch (deadlineStatus) {
      case "over":
        return themeSettings.colorError;
      case "today":
        return themeSettings.colorWarning;
      case "future":
        return themeSettings.colorPrimary;
      case "no date":
        return themeSettings.colorPrimary;
      default:
        return themeSettings.colorPrimary;
    }
  };

  const onMilestoneStatusChange = (value, row) => {
    const formData = {
      project_id: row?.project_id,
      milestone_id: row?.id,
      status_id: value,
    };
    updateProjectMilestone(formData);
  };

  const onMilestoneStartDateChange = (date, dateString, row) => {
    const formData = {
      project_id: row?.project_id,
      milestone_id: row?.id,
      start_date: formatDate(date, true),
      status_id: row?.status_id,
    };
    updateProjectMilestone(formData);
  };

  const onMilestoneEndDateChange = (date, dateString, row) => {
    const formData = {
      project_id: row?.project_id,
      milestone_id: row?.id,
      end_date: formatDate(date, true),
      status_id: row?.status_id,
    };
    updateProjectMilestone(formData);
  };

  const updateMilestoneNote = async (event, data) => {
    const customQuery = PROJECT_MILESTONE_NOTE_CREATE();
    const noteInputValue =
      milestoneNoteForm.getFieldValue("new_milestone_note");
    const formObject = {
      milestone_id: data?.id,
      note: noteInputValue,
    };

    if (!noteInputValue) {
      hideModal();
      return null;
    }

    const response = await postData(customQuery, formObject);

    if (response && response.code === 201) {
      notification.success({
        message: "Success",
        description: `Milestone Note Added Successfully`,
        placement: "topLeft",
      });
      refetch();
      setOpen(false);
      setMilestoneModalData(null);
      hideModal();
      onMilestoneClose();
    } else {
      notification.error({
        message: "Error",
        description: `Error Adding Milestone Note`,
        placement: "topLeft",
      });
    }
  };

  const milestoneColumns = [
    {
      title: "Milestone Name",
      key: "name",
      dataIndex: "name",
      width: "16%",
    },
    {
      title: "Status",
      key: "milestone_status",
      width: "16%",
      render(row) {
        return (
          <Select
            showSearch
            placeholder="Select status"
            optionFilterProp="children"
            onChange={(event) => onMilestoneStatusChange(event, row)}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            value={row?.milestone_status?.name || "N/A"}
            style={{ width: "90%" }}
            className="milestone_status_select"
          >
            {milestoneStatus?.map((e) => (
              <Option value={e.id} name={e.name} key={e.id}>
                {e.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Planning Date",
      key: "start_date",
      width: "18%",
      render: (row) => (
        <>
          <DatePicker
            value={row?.start_date ? moment(row?.start_date) : null}
            onChange={(date, dateString) =>
              onMilestoneStartDateChange(date, dateString, row)
            }
            style={{ width: "80%" }}
          />
        </>
      ),
    },
    {
      title: "Actual Date",
      key: "end_date",
      width: "18%",
      render: (row) => (
        <>
          <DatePicker
            value={row?.end_date ? moment(row?.end_date) : null}
            onChange={(date, dateString) =>
              onMilestoneEndDateChange(date, dateString, row)
            }
            style={{ width: "80%" }}
          />
        </>
      ),
    },
    {
      title: "Latest Note",
      key: "latest_note",
      width: "16%",
      render: (row) => {
        return (
          <div>
            {row?.milestone_notes?.length > 0
              ? row?.milestone_notes[row?.milestone_notes?.length - 1]?.note
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "edit_note",
      width: "12%",
      render: (row) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setOpen({ openedModal: "milestone_note" });
              setMilestoneModalData(row);
            }}
          >
            Edit Note
          </Button>
        );
      },
    },
  ];

  const customColumns = [
    {
      title: "Resource Name",
      key: "name",
      render(row) {
        if (row?.resource_details) {
          return (
            <>
              <div className="resourceInfoContainer">
                <Space>
                  <img
                    className="resourceImage"
                    src={
                      row?.resource_details?.photo
                        ? `${BASE_URL}/${row?.resource_details?.photo}`
                        : "/assets/icons/user_image_1.png"
                    }
                    alt="Resource"
                  />
                  <div className="resourceDetailsContainer">
                    <div className="resourceName">
                      {row?.resource_details?.name || ""}
                    </div>
                    <div className="resourcePositionName">
                      {row?.resource_details?.resource_type_details?.name || ""}
                    </div>
                  </div>
                </Space>
              </div>
            </>
          );
        }
      },
    },
    {
      title: "Engage Status",
      key: "engagement_level",
      render(row) {
        return (
          <Tag style={{ width: "62px" }} color={"warning"}>
            {row?.resource_details?.engagement_level || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Tasks",
      key: "task_count",
      render(row) {
        return row?.resource_details?.issue_count + " Task";
      },
    },
    {
      title: "Engaged From",
      key: "engaged_from",
      render: (row) => (
        <>
          <CalendarOutlined />{" "}
          {row?.engaged_from
            ? moment(row?.engaged_from).format("DD MMM YYYY")
            : "N/A"}
        </>
      ),
    },
    {
      title: "Engaged To",
      key: "engaged_to",
      render: (row) => (
        <>
          <CalendarOutlined />{" "}
          {row?.engaged_to
            ? moment(row?.engaged_to).format("DD MMM YYYY")
            : "N/A"}
        </>
      ),
    },
    {
      title: "Entry Hour",
      key: "worklogs",

      render: (row) => {
        const convertedTimeObject = secondsToTime(
          row?.resource_details?.workLog
        );
        const workLogInHour = convertedTimeObject?.hours;
        return <div>{`${workLogInHour}h` || "N/A"}</div>;
      },
    },
    {
      title: "Action",
      key: "project_resources",
      render: (row) => {
        return (
          <Button
            type="danger"
            onClick={() =>
              deleteConfirm(
                row?.resource_details?.id,
                row?.project_details?.id,
                removeResourceFromProject
              )
            }
          >
            Remove
          </Button>
        );
      },
    },
  ];

  const columns = [
    {
      title: "Project Name",
      key: "name",
      render: (row) => (
        <>
          <RadarChartOutlined
            style={{
              marginRight: "5px",
            }}
          />{" "}
          <a href={`project-overview/${row?.id}`}>{row?.name}</a>
          {"   "}
          {"   "}
          <ZoomInOutlined
            onClick={() => {
              showModal("project_overview");
              setModalData(row);
            }}
            style={{
              backgroundColor: "#1890FF",
              color: "white",
              width: "22px",
              padding: "3px",
              height: "20px",
              paddingTop: "3px",
              paddingLeft: "3px",
              marginLeft: "5px",
              fontSize: "14px",
              borderRadius: "3px",
            }}
          />
        </>
      ),
    },
    {
      title: "Deadline",
      key: "expected_end_date",
      dataIndex: "expected_end_date",
      render: (row) => {
        return (
          <div style={{ color: detectColor(detectDeadline(row)) }}>
            {row ? moment(row).format("DD MMM YYYY") : "N/A"}
          </div>
        );
      },
    },
    // {
    //   title: "Business Unit Name",
    //   key: "business_unit_id",
    //   render: (row) =>
    //     row?.business_units?.name || <Tag color={"warning"}>Not Assigned</Tag>,
    // },
    {
      title: "PMO Name",
      key: "pmo_name",
      dataIndex: "project_resources",
      render: (row) => {
        const foundResourceObject = getResourceByType(row, "PMO");
        const resourceName = foundResourceObject
          ? foundResourceObject?.resource_details?.name
          : "N/A";
        return <div>{resourceName || "N/A"}</div>;
      },
    },
    {
      title: "Milestone",
      key: "milestone",
      dataIndex: "project_milestones",
      render: (row) => (
        <>
          {" "}
          {row.length > 0 ? (
            <a
              onClick={() => {
                showMilestoneDrawer();
                setMilestoneRowData(row);
              }}
            >
              {row.length + " Milestone"}
            </a>
          ) : (
            "No Milestone"
          )}
        </>
      ),
    },
    {
      title: "Resource",
      key: "project_resources",
      render: (row) => (
        <>
          {" "}
          {row.project_resources.length > 0 ? (
            <a
              onClick={() => {
                showDrawer();
                setRowData(row);
              }}
            >
              {`${row.project_resources.length} Resource${
                row.project_resources.length > 1 ? "s" : ""
              }`}
            </a>
          ) : (
            "No Resource"
          )}
        </>
      ),
    },
    {
      title: "Status",
      key: "project_status",

      render: (row) => {
        return (
          <Tag
            style={{ width: "72px" }}
            color={row?.project_status?.color_code || "warning"}
          >
            {row?.project_status?.name || "N/A"}
          </Tag>
        );
      },
    },
    // {
    //   title: "Progress",
    //   key: "project_status",

    //   render: (row) => 20 + "%" || <Tag color={"warning"}>N/A</Tag>,
    // },
    {
      title: "Log Entry",
      key: "worklogs",
      dataIndex: "worklogs",
      render: (row) => {
        const convertedTimeObject = secondsToTime(row);
        const workLogInHour = convertedTimeObject?.hours;
        return <div>{`${workLogInHour}h` || "N/A"}</div>;
      },
    },
    !restrictActions
      ? {
          title: "Action",
          key: "action",
          fixed: "right",
          render: (row) => (
            <Space size="middle">
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  navigate(`/project/${row?.id}`);
                }}
              >
                <FormOutlined className="icon-primary-color" />
              </Button>
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={(e) => {
                  setLoader(true);
                  handleDelete({
                    variables: {
                      deleteTypeId: parseInt(row?.id),
                    },
                  });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button danger type="link" style={{ padding: 0 }}>
                  <DeleteOutlined className="icon-secondary-color" />
                </Button>
              </Popconfirm>
            </Space>
          ),
        }
      : {},
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <ContentHeader
            contentTitle={contentTitle ? contentTitle : "Projects"}
            primaryButtonTitle={!restrictActions ? "Create Project" : ""}
            primaryButtonClickFunction={
              !restrictActions ? () => navigate("/createproject") : null
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="card mb-4">
            <CommonPanel
              defaultActiveKey="1"
              headerText="Filter"
              filterQueryName="return_request_filter"
            >
              <Form
                form={projectForm}
                autoComplete="off"
                scrollToFirstError={true}
                layout={"horizontal"}
                onFinish={onFinish}
                style={{ padding: "1rem" }}
              >
                <Row gutter={5}>
                  {/* Order ID */}
                  <Col span={5}>
                    <Form.Item
                      label="Project Name"
                      name="name"
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        placeholder="Project Name"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  {/* Customer Phone */}
                  <Col span={5}>
                    <Form.Item
                      name="status"
                      label="Project Status"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        showSearch
                        placeholder="Select status"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {milestoneStatus?.map((e) => (
                          <Option value={e.id} name={e.name} key={e.id}>
                            {e.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* Start and End Date */}
                  <Col span={8}>
                    <Form.Item
                      label="Date Range Filter"
                      name="dates"
                      labelCol={{ span: 24 }}
                    >
                      <RangePicker
                        format={"YY-MM-DD HH:mm:ss"}
                        bordered={true}
                        style={{ display: "flex" }}
                        showTime={true}
                      />
                    </Form.Item>
                  </Col>

                  {/* Buttons */}
                  <Col span={3} style={{ marginTop: 40 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Filter
                    </Button>
                  </Col>
                  <Col span={3} style={{ marginTop: 40 }}>
                    <Button
                      type="ghost"
                      onClick={filterReset}
                      style={{ width: "100%" }}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CommonPanel>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ paddingTop: 10 }} className="card mb-4">
            <Card>
              <Table
                scroll={{ x: true }}
                size="middle"
                pagination={true}
                onChange={(newPage) => {
                  onPageChange(newPage);
                }}
                columns={columns}
                refresh={refresh}
                dataSource={projects || []}
              />

              <Drawer
                title="Project Milestone Details"
                placement="right"
                onClose={onMilestoneClose}
                width="1000"
                visible={milestoneDetailsVisible}
              >
                {milestoneRowData &&
                  milestoneRowData.map((detailItem, index) => {
                    if (detailItem.milestone_status.name == "WIP") {
                      return (
                        <>
                          <Text type="secondary">Running Milestone Name</Text>
                          <div>
                            <h3>{detailItem.name}</h3>

                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  width: "50%",
                                  height: "100px",
                                  float: "left",
                                }}
                              >
                                <Text type="secondary">Plan Date</Text>
                                <br />
                                {detailItem.start_date
                                  ? moment(detailItem.start_date).format(
                                      "DD MMM YYYY"
                                    )
                                  : "N/A"}
                              </div>
                              <div
                                style={{
                                  marginLeft: "50%",
                                  height: "100px",
                                }}
                              >
                                <Text type="secondary">Actual Date</Text>
                                <br />
                                {detailItem.end_date
                                  ? moment(detailItem.end_date).format(
                                      "DD MMM YYYY"
                                    )
                                  : "N/A"}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}

                <Table
                  dataSource={milestoneRowData}
                  columns={milestoneColumns}
                  refresh={refresh}
                />
              </Drawer>

              <Drawer
                title="Resource Engage Details"
                placement="right"
                onClose={onClose}
                visible={detailsVisible}
                width="920"
                refresh={refresh}
              >
                <Text type="secondary">Engage Resource Count</Text>
                <br />

                {rowData && rowData.project_resources.length}
                <br />
                <br />
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "50%",
                      height: "100px",
                      float: "left",
                    }}
                  >
                    <Text type="secondary">Engaged From</Text>
                    <br />
                    {rowData?.start_date
                      ? moment(rowData.start_date).format("DD MMM YYYY")
                      : "N/A"}
                  </div>
                  <div
                    style={{
                      marginLeft: "50%",
                      height: "100px",
                    }}
                  >
                    <Text type="secondary">Engaged To</Text>
                    <br />
                    {rowData?.expected_end_date
                      ? moment(rowData?.expected_end_date).format("DD MMM YYYY")
                      : "N/A"}
                  </div>
                </div>
                <p>
                  Resource List ({rowData && rowData.project_resources?.length})
                </p>
                <Table
                  dataSource={rowData?.project_resources}
                  columns={customColumns}
                  refresh={refresh}
                />
              </Drawer>
            </Card>
          </div>
        </Col>
      </Row>

      <Modal
        title="Project Details"
        open={open?.openedModal === "project_overview"}
        onOk={hideModal}
        onCancel={hideModal}
      >
        <Text type="secondary">Project Name</Text>
        <br />
        <h4>{modalData?.name}</h4>

        <Text type="secondary">Project Status</Text>
        <br />
        <Tag color={"warning"}>{modalData?.project_status?.name}</Tag>
        <br />
        <br />
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "50%",
              height: "100px",
              float: "left",
            }}
          >
            <Text type="secondary">WO Start & End Date</Text>
            <br />
            <CalendarOutlined />{" "}
            {modalData?.start_date
              ? moment(modalData?.wo_start_date).format("DD MMM YYYY")
              : "N/A"}{" "}
            -{" "}
            {modalData?.wo_end_date
              ? moment(modalData?.wo_end_date).format("DD MMM YYYY")
              : "N/A"}
            <br />
            <br />
            <Text type="secondary">Total Resource Engagement</Text>
            <br />
            {modalData &&
              modalData.project_resources.slice(0, 3).map((details, index) => {
                return (
                  <Space>
                    <Popover
                      content={details?.resource_details?.name}
                      trigger="hover"
                    >
                      <img
                        className="resourceImage"
                        src={
                          details?.resource_details?.photo
                            ? `${BASE_URL}/${details?.resource_details?.photo}`
                            : "/assets/icons/user_image_1.png"
                        }
                        alt={details?.resource_details?.name}
                        style={{ padding: "2px" }}
                      />
                    </Popover>
                  </Space>
                );
              })}
            {modalData?.project_resources?.length > 3}
            {modalData?.project_resources?.length - 3 > 0
              ? "+" + (modalData?.project_resources?.length - 3) + " others"
              : ""}
          </div>

          <div
            style={{
              marginLeft: "50%",
              height: "100px",
            }}
          >
            <Text type="secondary">
              Milestone ({modalData?.project_milestones?.length})
            </Text>
            <br />
            <Progress
              percent={calculateMilestonePercent(modalData?.project_milestones)}
            />
            <br />
            <br />

            <Text type="secondary">Checklist Count</Text>
            <br />
            {modalData?.project_checklists?.length}
          </div>
        </div>
        <br />
        <br />

        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "50%",
              height: "100px",
              float: "left",
            }}
          >
            <h4 type="secondary">Project Hour</h4>

            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "50%",
                  height: "100px",
                  float: "left",
                }}
              >
                <Text type="secondary">Estimated</Text>
                <br />
                {modalData?.expected_hour + " h"}
              </div>

              <div
                style={{
                  marginLeft: "50%",
                  height: "100px",
                }}
              >
                <Text type="secondary">Spend</Text>
                <br />
                {secondsToTime(modalData?.worklogs)?.hours + " h"}
              </div>
            </div>
          </div>

          <div
            style={{
              marginLeft: "50%",
              height: "100px",
            }}
          ></div>
        </div>
      </Modal>

      <Modal
        title="Milestone Notes"
        open={open?.openedModal === "milestone_note"}
        onOk={(event) => updateMilestoneNote(event, milestoneModalData)}
        onCancel={hideModal}
      >
        <Timeline mode={"right"}>
          {milestoneModalData?.milestone_notes &&
            milestoneModalData?.milestone_notes?.length > 0 &&
            milestoneModalData?.milestone_notes?.map((item, index) => {
              return (
                <Timeline.Item
                  label={
                    item?.created_at ? formatDate(item?.created_at, true) : ""
                  }
                >
                  {item?.note}
                </Timeline.Item>
              );
            })}
          <Form
            className="milestoneForm"
            layout="vertical"
            onFinish={(event) => updateMilestoneNote(event, milestoneModalData)}
            form={milestoneNoteForm}
          >
            <Form.Item name="new_milestone_note">
              <Input placeholder="Add New Note" style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </Timeline>
      </Modal>
    </>
  );
};

export default ProjectComponent;

import { Row, Col, Form, Input, Switch, Select, Option } from "antd";
import "./TeamsForm.scss";
import React, { useState, useEffect } from "react";
import { RESOURCE_ALL_LIST } from "../../../utils/api";
import { postData, getData } from "../../../utils/api-service";

const TeamsForm = (props) => {
  const { Option } = Select;
  // Props
  const { onFinish, formName, flag, rowData, visible } = props;
  const [resources, setResources] = useState();

  useEffect(() => {
    if (flag === "Create") {
      formName.setFieldsValue({
        name: null,
        team_lead: null,
      });
    }
    if (flag === "Edit") {
      formName.setFieldsValue({
        name: rowData?.name,
        team_lead: rowData?.team_lead,
      });
    }
  }, [formName, visible, flag, rowData]);

  const onChange = (value) => {};

  const onSearch = (value) => {};

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let query = RESOURCE_ALL_LIST();
      let response = await getData(query);
      if (response && response.code === 200) {
        setResources(response.data);
      }
    };

    // call the function
    fetchData();
    // make sure to catch any error
  }, []);
  return (
    <Form layout="vertical" onFinish={onFinish} form={formName}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Please enter name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="team_lead" label="Team Lead">
            <Select
              showSearch
              placeholder="Select Lead"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {resources?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TeamsForm;

import { Row, Col } from "antd";
import { ChartComponent } from "components";

const OverviewCharts = (props) => {
    const { projectDetailsData } = props;

    return (
        <Row gutter={[16, 16]} className="overview-chart-container">
            <Col xs={24} sm={12} md={6}>
                <ChartComponent
                    data={projectDetailsData?.resource_graph?.data || null}
                    totalData={
                        projectDetailsData?.resource_graph?.total_data || null
                    }
                    headLine="Total Resources"
                    chartType="pie"
                    timeLine="Lifetime"
                />
            </Col>
        </Row>
    );
};

export default OverviewCharts;

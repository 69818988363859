import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AuthContextProvider,
  RouteContextProvider,
  ThemeContextProvider,
} from "context";
import {
  LayoutWrapper,
  ErrorPage,
  DashboardComponent,
  ProjectTypeComponent,
  ChargeTypeComponent,
  BusinessUnitComponent,
  SupportStatusComponent,
  MilestoneStatusComponent,
  ResourceComponent,
  TeamsComponent,
  ProjectComponent,
  ResourceTypeComponent,
  ProjectDetailsComponent,
  ProjectOverViewComponent,
  SkillSetComponent,
  Login,
  RequireAuth,
  ActivityLogComponent,
} from "components";
import "styles/index.scss";

function RootComponent() {
  const routesArray = [
    {
      id: 1,
      name: "dashboard",
      path: "/",
      component: <DashboardComponent />,
      allowedPermissions: ["dashboard-show"],
    },
    {
      id: 2,
      name: "projects",
      path: "/projects",
      component: <ProjectComponent />,
      allowedPermissions: ["projects-show"],
    },
    {
      id: 3,
      name: "project-details",
      path: "/project/:projectId",
      component: <ProjectDetailsComponent />,
      allowedPermissions: ["project-details-show"],
    },
    {
      id: 4,
      name: "project-overview",
      path: "/project-overview/:projectId",
      component: <ProjectOverViewComponent />,
      allowedPermissions: ["project-overview-show"],
    },
    {
      id: 5,
      name: "create-project",
      path: "/createproject",
      component: <ProjectDetailsComponent />,
      allowedPermissions: ["create-project-show"],
    },
    {
      id: 6,
      name: "project-type",
      path: "/project-type",
      component: <ProjectTypeComponent />,
      allowedPermissions: ["project-type-show"],
    },
    {
      id: 7,
      name: "charge-type",
      path: "/charge-type",
      component: <ChargeTypeComponent />,
      allowedPermissions: ["charge-type-show"],
    },
    {
      id: 8,
      name: "business-unit",
      path: "/business-unit",
      component: <BusinessUnitComponent />,
      allowedPermissions: ["business-unit-show"],
    },
    {
      id: 9,
      name: "support-status",
      path: "/support-status",
      component: <SupportStatusComponent />,
      allowedPermissions: ["support-status-show"],
    },
    {
      id: 10,
      name: "milestone-status",
      path: "/milestone-status",
      component: <MilestoneStatusComponent />,
      allowedPermissions: ["milestone-status-show"],
    },
    {
      id: 11,
      name: "team",
      path: "/team",
      component: <TeamsComponent />,
      allowedPermissions: ["team-show"],
    },
    {
      id: 12,
      name: "resource",
      path: "/resource",
      component: <ResourceComponent comingFrom="resource" />,
      allowedPermissions: ["resource-show"],
    },
    {
      id: 13,
      name: "resource-type",
      path: "/resource-type",
      component: <ResourceTypeComponent />,
      allowedPermissions: ["resource-type-show"],
    },
    {
      id: 14,
      name: "skill-set",
      path: "/skill-set",
      component: <SkillSetComponent />,
      allowedPermissions: ["resource-type-show"],
    },
    {
      id: 14,
      name: "activity-logs",
      path: "/activity-logs",
      component: <ActivityLogComponent />,
      allowedPermissions: ["resource-type-show"],
    },
  ];

  return (
    <div className="App">
      <ThemeContextProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <RouteContextProvider>
              <Routes>
                {/* Public Routes: does not required authentication */}
                <Route path="/login" exact element={<Login />} />
                <Route path="*" element={<ErrorPage />} />
                {/* Routes that required authentication */}
                {routesArray?.map((routeItem, index) => {
                  return (
                    <Route
                      element={
                        <RequireAuth
                          allowedPermissions={routeItem?.allowedPermissions}
                        />
                      }
                      key={routeItem?.id}
                    >
                      <Route
                        path={routeItem?.path}
                        element={
                          <LayoutWrapper component={routeItem?.component} />
                        }
                      />
                    </Route>
                  );
                })}
              </Routes>
            </RouteContextProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </ThemeContextProvider>
    </div>
  );
}

export default RootComponent;

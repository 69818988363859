import {
    Row,
    Col,
} from "antd";
import moment from 'moment';

const ProjectInfoContainer = (props) => {
    // Props
    const { projectDetailsData } = props;
    
    return(
        <Row gutter={16} className="overview-project-details">
            {/* Project Duration Info Column */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Row>
                    <Col span={24}>
                        <div className="info-header">Project Duration</div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                WO Ref
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.wo_ref || "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                WO Start & End Date
                            </div>
                            <div className="overview-title-medium">
                                {`${
                                    projectDetailsData?.wo_start_date
                                        ? moment(
                                              projectDetailsData?.wo_start_date
                                          ).format("DD MMM YYYY")
                                        : "N/A"
                                } - ${
                                    projectDetailsData?.wo_end_date
                                        ? moment(
                                              projectDetailsData?.wo_end_date
                                          ).format("DD MMM YYYY")
                                        : "N/A"
                                }`}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Plan Date
                            </div>
                            <div className="overview-title-medium">
                                {`${
                                    projectDetailsData?.start_date
                                        ? moment(
                                              projectDetailsData?.start_date
                                          ).format("DD MMM YYYY")
                                        : "N/A"
                                } - ${
                                    projectDetailsData?.expected_end_date
                                        ? moment(
                                              projectDetailsData?.expected_end_date
                                          ).format("DD MMM YYYY")
                                        : "N/A"
                                }`}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Estimated Project Hour
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.expected_hour || "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Spend Hour
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.spent_hour || "N/A"}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            {/* Project Option Info Column */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Row>
                    <Col span={24}>
                        <div className="info-header">Project Option</div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Charge Type
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.charge_type?.name ||
                                    "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Support Status
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.support_status?.name || "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">OTC</div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.otc || "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                MRC
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.mrc || "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                AMC
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.amc || "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                MRC/AMC Start & End Date
                            </div>
                            <div className="overview-title-medium">
                                {`${
                                    projectDetailsData?.start_date
                                        ? moment(
                                              projectDetailsData?.start_date
                                          ).format("DD MMM YYYY")
                                        : "N/A"
                                } - ${
                                    projectDetailsData?.expected_end_date
                                        ? moment(
                                              projectDetailsData?.expected_end_date
                                          ).format("DD MMM YYYY")
                                        : "N/A"
                                }`}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            {/* Project Information Info Column */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Row>
                    <Col span={24}>
                        <div className="info-header">
                            Project Information
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Jira Key
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.jira_key ||
                                    "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Project ID
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.id ||
                                    "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Project Type
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.project_types?.type_name ||
                                    "N/A"}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="info-card">
                            <div className="overview-title-small">
                                Business Unit Name
                            </div>
                            <div className="overview-title-medium">
                                {projectDetailsData?.business_units?.name ||
                                    "N/A"}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ProjectInfoContainer;
import { Pie, Column } from "@ant-design/plots";
import { Divider } from "antd";
import { numberWithCommas } from 'utils/functions';
import "./ChartComponent.scss";

const ChartComponent = (props) => {
    // Props
    const {
        data,
        headLine,
        totalData,
        chartType,
        timeLine,
        chartName,
        hideTotalCount,
    } = props;


    const pieChartConfig = {
        data,
        appendPadding: 10,
        angleField: "value",
        colorField: "name",
        color: [
            "#F7D766",
            "#517BC3",
            "#EE7878",
            "#9C77FC",
            "#56C84E",
            "#22BFD7",
            "#229954",
            "#AF601A",
            "#BA4A00",
            '#117A65',
            '#9B59B6',
        ],
        radius: 0.9,
        label: {
            type: "inner",
            offset: "-30%",
            content: ({ percent, type }) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 14,
                textAlign: "center",
            },
        },
        interactions: [
            {
                type: "element-active",
            },
        ],
        legend: {
            offsetX: -22,
            itemValue: {
                formatter: (text, item) => {
                    const items = data.filter((d) => d.name === item.value);
                    const countedValue = items.length
                        ? items.reduce((a, b) => a + b.value, 0) / items.length
                        : "";
                    return `(${countedValue})`;
                },
                style: {
                    fontWeight: "bold",
                },
            },
        },
    };

    const columnChartConfig = {
        data,
        xField: "name",
        yField: "value",
        label: {
            position: "middle",
            style: {
                fill: "#FFFFFF",
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            type: {
                alias: "类别",
            },
            sales: {
                alias: "销售额",
            },
        },
    };

    const renderChart = (chartType) => {
        switch (chartType) {
            case "pie":
                return <Pie {...pieChartConfig} />;
            case "column":
                return <Column {...columnChartConfig} />;
            default:
                return null;
        }
    };

    const renderTotalCount = (data, chartName) => {
        const convertedData = numberWithCommas(data);

        if(convertedData && chartName === "loghour_chart") {
            return `${convertedData}h`;
        }
        if(convertedData && chartName === "costing_chart") {
            return `৳${convertedData}`;
        }

        // Return `N/A` if no condition matches above
        return data ? data :`N/A`;
    }

    return (
        <div className="chart-container">
            <div className="chart-header">
                <div className="chart-headline">
                    <div className="headLine">
                      {headLine || ""}
                    </div>
                    <div>{timeLine}</div>
                </div>
                {!hideTotalCount && (
                    <div className="chart-total-count">
                        {totalData ? renderTotalCount(totalData, chartName) : "N/A"}
                    </div>
                )}
            </div>
            <Divider style={{ margin: "0" }} />
            <div className="chart-parent">
                {data ? renderChart(chartType) : <div>No Data Found</div>}
            </div>
        </div>
    );
};

export default ChartComponent;

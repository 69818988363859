//mixed

export const PROJECT_TYPE_CREATE = () => {
  return `project-type/create`;
};

export const PROJECT_TYPE_DELETE = () => {
  return `project-type/delete`;
};

export const PROJECT_TYPE_UPDATE = () => {
  return `project-type/update`;
};

export const PROJECT_TYPE_LIST = () => {
  return `project-types`;
};

export const CHARGE_TYPE_CREATE = () => {
  return `charge-type/create`;
};

export const CHARGE_TYPE_DELETE = () => {
  return `charge-type/delete`;
};

export const CHARGE_TYPE_LIST = () => {
  return `charge-types`;
};

export const CHARGE_TYPE_UPDATE = () => {
  return `charge-type/update`;
};

export const BUSINESS_UNIT_CREATE = () => {
  return `business-unit/create`;
};

export const BUSINESS_UNIT_DELETE = () => {
  return `business-unit/delete`;
};

export const BUSINESS_UNIT_UPDATE = () => {
  return `business-unit/update`;
};

export const BUSINESS_UNIT_LIST = () => {
  return `business-units`;
};

export const SUPPORT_STATUS_CREATE = () => {
  return `support-status/create`;
};

export const SUPPORT_STATUS_DELETE = () => {
  return `support-status/delete`;
};

export const SUPPORT_STATUS_UPDATE = () => {
  return `support-status/update`;
};

export const SUPPORT_STATUS_LIST = () => {
  return `support-status`;
};
export const MILESTONE_STATUS_CREATE = () => {
  return `milestone-status/create`;
};

export const ACTIVITY_LOGS_LIST = () => {
  return `activity-logs`;
};

export const PROJECTS_LIST_ALL = () => {
  return `projects-all`;
};
export const USER_LIST_ALL = () => {
  return `users-all`;
};

export const MILESTONE_STATUS_DELETE = () => {
  return `milestone-status/delete`;
};

export const MILESTONE_STATUS_LIST = () => {
  return `milestone-status`;
};

export const MILESTONE_STATUS_UPDATE = () => {
  return `milestone-status/update`;
};

export const RESOURCE_CREATE = () => {
  return `resource/create`;
};

export const RESOURCE_DELETE = () => {
  return `resource/delete`;
};

export const RESOURCE_UPDATE = () => {
  return `resource/update`;
};

export const RESOURCE_LIST = () => {
  return `resources`;
};
export const RESOURCE_ALL_LIST = () => {
  return `resource-list`;
};

export const TEAMS_LIST = () => {
  return `teams`;
};

export const TEAMS_CREATE = () => {
  return `team/create`;
};

export const TEAMS_UPDATE = () => {
  return `team/update`;
};

export const TEAMS_DELETE = () => {
  return `team/delete`;
};
export const PROJECTS_LIST = () => {
  return `projects`;
};

export const PROJECT_CREATE = () => {
  return `project/create`;
};

export const PROJECTS_UPDTAE = () => {
  return `project/update`;
};
export const PROJECTS_DELETE = () => {
  return `project/delete`;
};

export const RESOURCE_TYPE_CREATE = () => {
  return `resource-type/create`;
};

export const RESOURCE_TYPE_DELETE = () => {
  return `resource-type/delete`;
};

export const RESOURCE_TYPE_LIST = () => {
  return `resource-types`;
};

export const RESOURCE_TYPE_UPDATE = () => {
  return `resource-type/update`;
};

export const GET_DASHBOARD_SUMMARY = () => {
  return `dashboard/summary`;
};

export const PROJECT_DETAILS_GET = () => {
  return `project/details`;
};

export const PROJECT_DETAILS_UPDATE = () => {
  return `project/update`;
};

export const PROJECT_MILESTONE_GET = () => {
  return `project-milestones`;
};

export const PROJECT_MILESTONE_CREATE = () => {
  return `project-milestone/create`;
};

export const PROJECT_MILESTONE_UPDATE = () => {
  return `project-milestone/update`;
};

export const PROJECT_MILESTONE_DELETE = () => {
  return `project-milestone/delete`;
};

export const PROJECT_MILESTONE_REMOVEALL = () => {
  return `project-milestone/remove-all`;
};

export const PROJECT_MILESTONE_NOTE_CREATE = () => {
  return `project-milestone-note/create`;
};

export const PROJECT_CHECKLIST_GET = () => {
  return `project-checklists`;
};

export const PROJECT_CHECKLIST_CREATE = () => {
  return `project-checklist/create`;
};

export const PROJECT_CHECKLIST_UPDATE = () => {
  return `project-checklist/update`;
};

export const PROJECT_CHECKLIST_DELETE = () => {
  return `project-checklist/delete`;
};

export const PROJECT_CHECKLIST_REMOVEALL = () => {
  return `project-checklist/remove-all`;
};

export const PROJECT_RESOURCES_GET = () => {
  return `project-resources`;
};

export const PROJECT_RESOURCES_CREATE = () => {
  return `project-resource/create`;
};

export const PROJECT_RESOURCES_UPDATE = () => {
  return `project-resource/update`;
};

export const PROJECT_RESOURCES_DELETE = () => {
  return `project-resource/remove`;
};

export const PROJECT_RESOURCES_REMOVEALL = () => {
  return `project-resource/remove-all`;
};

export const PROJECT_NOTE_CREATE = () => {
  return `project-note/create`;
};

export const PROJECT_STATUS_UPDATE = () => {
  return `project-status/update`;
};

export const PROJECT_MILESTONE_STATUS_UPDATE = () => {
  return `project-milestone/update`;
};

export const SKILL_SET_LIST = () => {
  return `skill-sets`;
};

export const SKILL_SET_CREATE = () => {
  return `skill-set/create`;
};

export const SKILL_SET_UPDATE = () => {
  return `skill-set/update`;
};

export const SKILL_SET_DELETE = () => {
  return `skill-set/delete`;
};

export const LOGIN = () => {
  return `login`;
};

export const FILE_UPLAOD = () => {
  return `temporary-upload`;
};

export const GET_ATTACHMENT_TYPES = () => {
  return `attachment-types`;
};

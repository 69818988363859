import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Space,
  Card,
  Button,
  Drawer,
  Form,
  notification,
  Popconfirm,
  Table,
  Tag,
  Image,
  Input,
  Select,
  Tooltip,
  Text,
  Typography,
  Modal,
  InputNumber,
} from "antd";
import {
  DeleteOutlined,
  FormOutlined,
  ExclamationCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { ContentHeader, ResourceForm } from "components";
import "./ResourceComponent.scss";
import {
  RESOURCE_CREATE,
  RESOURCE_LIST,
  RESOURCE_DELETE,
  RESOURCE_UPDATE,
  TEAMS_LIST,
  SKILL_SET_LIST,
  PROJECT_RESOURCES_DELETE,
} from "../../utils/api";
import { formatDate, generateEngagementDates } from "../../utils/functions";
import { BASE_URL } from "../../utils/constants";
import { postData, getData } from "../../utils/api-service";
import moment from "moment";
import CommonPanel from "../../components/common/CommonPanel";
import generateCalendar from "antd/lib/calendar/generateCalendar";

const ResourceComponent = (props) => {
  // Props
  const {
    setParentCurrentPage,
    projectResourceList,
    restrictActions,
    comingFrom,
  } = props;

  // States
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [flag, setFlag] = useState(null);
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [resourceProjectRowData, setResourceProjectRowData] = useState(null);
  const [resource, setResource] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [teams, setTeams] = useState();
  const [filterQueryData, setFilterQueryData] = useState({});
  const [open, setOpen] = useState(false);
  const [resourceProjectDrawer, setResourceProjectDrawer] = useState(false);
  const [skillSet, setSkillSet] = useState(null);
  const [page, setPage] = useState(1);
  // Antd
  const [resourceForm] = Form.useForm();
  const { Text, Title } = Typography;
  const { Option } = Select;
  const { confirm } = Modal;

  const onChange = (value) => {};

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const onSearch = (value) => {};

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const onClose = () => {
    setResourceProjectDrawer(false);
  };

  const showResourceProjectDrawer = () => {
    setResourceProjectDrawer(true);
    setFlag("Create");
  };

  const showDrawer = () => {
    setDrawerVisible(true);
    setFlag("Create");
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setFlag(null);
    resourceForm.setFieldsValue({});
  };

  const removeResourceFromProject = async (resourceID, projectID) => {
    const deleteQuery = PROJECT_RESOURCES_DELETE();
    const currentProjectId = projectID;
    const customQuery = `${deleteQuery}`;
    const formObject = {
      projects_id: currentProjectId,
      resources_id: resourceID,
    };
    const response = await postData(customQuery, formObject);

    if (response && response.code === 201) {
      notification.success({
        message: "Success",
        description: `Resource Removed Successfully`,
        placement: "topLeft",
      });
      refetch();
      setResourceProjectDrawer(false);
    } else {
      notification.error({
        message: "Error",
        description: `Error Removing Resource`,
        placement: "topLeft",
      });
    }
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onFinish = (values) => {
    setLoader(true);
    submitResourceForm(values);
  };

  const filterReset = () => {
    resourceForm.resetFields();
    setFilterQueryData({});
    refetch();
  };

  const onFilterFinish = (values) => {
    let filterQueryObject = {
      ...(values?.resource_name && {
        resource_name: values.resource_name,
      }),
      ...(values?.resource_employee_id && {
        employee_id: values.resource_employee_id,
      }),
      ...(values?.resource_team_id && {
        team_id: values.resource_team_id,
      }),
      ...(values?.resource_engagement_level && {
        engagement_level: values.resource_engagement_level,
      }),
      ...(values?.num_of_projects && {
        num_of_projects: values.num_of_projects,
      }),
      ...(values?.skill_set && {
        assigned_skill_set: JSON.stringify(values.skill_set),
      }),
    };

    setFilterQueryData(filterQueryObject);
    refetch();
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let query = TEAMS_LIST();

      let response = await getData(query);
      if (response && response.code === 200) {
        setTeams(response.data);
      }

      let skillQuery = SKILL_SET_LIST();

      let skillResponse = await getData(skillQuery);

      if (skillResponse && skillResponse.code === 200) {
        setSkillSet(skillResponse.data.data);
      }
    };

    // call the function
    fetchData();
    // make sure to catch any error
  }, []);

  const submitResourceForm = async (values) => {
    if (flag === "Create") {
      let requestBody = {};

      requestBody.employee_id = values.employee_id;
      requestBody.is_active = values.is_active ? values.is_active : false;
      requestBody.email = values.email ? values.email : "";
      requestBody.name = values.name ? values.name : "";
      requestBody.phone = values.phone ? values.phone : "";
      requestBody.cost_per_hour = values.cost_per_hour
        ? values.cost_per_hour
        : "";
      requestBody.team_id = values.team_id ? values.team_id : "";
      requestBody.photo = values.photo?.file?.response
        ? values.photo?.file?.response?.[0]
        : "";
      requestBody.resource_type_id = values.resource_type_id || "";
      requestBody.engagement_level = values.engagement_level || "";
      requestBody.assigned_skill = values.assigned_skill
        ? JSON.stringify(values.assigned_skill)
        : Array;
      let query = RESOURCE_CREATE();
      let response = await postData(query, requestBody);
      if (response && response.code === 201) {
        closeDrawer();
        notification.success({
          message: "Success",
          description: `${"Saved successfully"}`,
          placement: "topLeft",
        });

        setLoader(false);
        refetch();
      } else {
        if (response?.messages) {
          response?.messages?.map((errorItem, index) => {
            return notification.error({
              message: `${errorItem}`,
              placement: "topLeft",
            });
          });
        } else {
          notification.error({
            message: "Error",
            placement: "topLeft",
          });
        }
      }
    }

    if (flag === "Edit") {
      let requestBody = {};
      requestBody.employee_id = values.employee_id;
      requestBody.is_active = values.is_active ? values.is_active : false;
      requestBody.email = values.email ? values.email : "";
      requestBody.name = values.name ? values.name : "";
      requestBody.phone = values.phone ? values.phone : "";
      requestBody.cost_per_hour = values.cost_per_hour
        ? values.cost_per_hour
        : "";
      requestBody.team_id = values.team_id ? values.team_id : "";
      requestBody.photo = values.photo?.file?.response
        ? values.photo?.file?.response?.[0]
        : rowData?.photo || "";
      requestBody.resource_type_id =
        values.resource_type_id || values.resource_type_id;

      requestBody.engagement_level =
        values.engagement_level || values.engagement_level;

      requestBody.assigned_skill = values.assigned_skill
        ? JSON.stringify(values.assigned_skill)
        : rowData?.assigned_skill;

      let query =
        RESOURCE_UPDATE() + "/" + parseInt(rowData?.resource_details?.id);
      let response = await postData(query, requestBody);
      if (response && response.code === 201) {
        closeDrawer();
        notification.success({
          message: "Success",
          description: `${"Updated successfully"}`,
          placement: "topLeft",
        });

        setLoader(false);
        refetch();
      } else {
        notification.error({
          message: "Error",
          placement: "topLeft",
        });
      }
    }
  };

  const resetFlagAndRowData = () => {
    setFlag("");
    setRowData(null);
  };

  const cancel = (e) => {};

  const handleDelete = async (id) => {
    let query = RESOURCE_DELETE() + "/" + id;
    let response = await postData(query);
    if (response && response.code === 200) {
      closeDrawer();
      notification.success({
        message: "Success",
        description: `${"Resource Deleted Successfully"}`,
        placement: "topLeft",
      });

      setLoader(false);
    } else {
      notification.error({
        message: "Error",
        placement: "topLeft",
      });
    }

    refetch();
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let query = RESOURCE_LIST();
      let response = await getData(query, filterQueryData);
      if (response && response.code === 200) {
        setResource(response.data.data);
        setPageCount(response?.data?.data?.total);
        setLoader(false);
      } else {
        notification.error({
          message: "Error getting data",
          placement: "topLeft",
        });

        setLoader(false);
      }
    };

    // If coming from dashboard/resource list page then fetch data and show all resources
    // Else if has projectResourceList then set to resource state
    if (comingFrom === "dashboard" || comingFrom === "resource") {
      fetchData();
    } else {
      if (projectResourceList) {
        setResource(projectResourceList);
      }
    }
    // make sure to catch any error
  }, [refresh, projectResourceList, comingFrom, filterQueryData]);

  const setCurrentPageToStates = (page) => {
    setCurrentPage(page);

    if (setParentCurrentPage) {
      setParentCurrentPage(page);
    }
  };

  const paginate = (page) => {
    setCurrentPageToStates(page);
  };

  const deleteConfirm = (id, projectID, deleteFunction) => {
    confirm({
      title: "Are you sure you want to remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteFunction(id, projectID);
      },

      onCancel() {
        return null;
      },
    });
  };

  const sizeChange = (currentPage, size) => {
    setCurrentPageToStates(currentPage);
    setPerPageCount(size);
  };

  const processTooltipData = (resourceProjects) => {
    let projectsName = [];

    resourceProjects.forEach((item) => {
      projectsName.push(item.name);
    });

    if (projectsName.length > 0) {
      return projectsName.toString();
    } else {
      return "No Project";
    }
  };

  const generateTotalHour = (from, to) => {
    if (from && to) {
      var fromDate = parseInt(new Date(from).getTime() / 1000);
      var toDate = parseInt(new Date(to).getTime() / 1000);
      var timeDiff = (toDate - fromDate) / 3600;
      var finaltimeDiff = Math.round((timeDiff / 24) * 8);
      return finaltimeDiff + " h";
    } else {
      return "Free";
    }
  };

  const customColumns = [
    {
      title: "Projects Name",
      key: "resource_projects",
      render: (row) => <> {row.name}</>,
    },

    {
      title: "Task",
      key: "resource_projects",
      render: (row) => <> {row.issue_count}</>,
    },
    {
      title: "Engage Status",
      key: "resource_projects",
      render: (row) => <> {row?.resource?.engagement_level || "N/A"}</>,
    },
    {
      title: "Engaged From",
      render: (row) => (
        <>
          <CalendarOutlined />{" "}
          {row?.engagement_history
            ? moment(row?.engagement_history?.engaged_from).format(
                "DD MMM YYYY"
              )
            : "N/A"}
        </>
      ),
    },
    {
      title: "Engaged To",
      key: "resource_details",
      render: (row) => (
        <>
          <CalendarOutlined />{" "}
          {row?.engagement_history
            ? moment(row?.engagement_history?.engaged_to).format("DD MMM YYYY")
            : "N/A"}
        </>
      ),
    },
    {
      title: "Action",
      key: "project_resources",
      render: (row) => {
        return (
          <Button
            type="danger"
            onClick={() =>
              deleteConfirm(
                row?.resource?.id,
                row?.id,
                removeResourceFromProject
              )
            }
          >
            Remove
          </Button>
        );
      },
    },
  ];

  const resourcesColumns = [
    {
      title: "Resource Name",
      key: "name",
      render(row) {
        if (row?.resource_details) {
          return (
            <>
              <div className="resourceInfoContainer">
                <Space>
                  <img
                    className="resourceImage"
                    src={
                      row?.resource_details?.photo
                        ? `${BASE_URL}/${row?.resource_details?.photo}`
                        : "/assets/icons/user_image_1.png"
                    }
                    alt="Resource"
                  />
                  <div className="resourceDetailsContainer">
                    <div className="resourceName">
                      {row?.resource_details?.name || ""}
                    </div>
                    <div className="resourcePositionName">
                      {row?.resource_details?.resource_type_details?.name || ""}
                    </div>
                  </div>
                </Space>
              </div>
            </>
          );
        }
      },
    },
    {
      title: "Engaged In",
      key: "employee_id",
      render: (row) => (
        <>
          <>
            {" "}
            {row?.resource_details?.resource_projects.length > 0 ? (
              <a
                onClick={() => {
                  showResourceProjectDrawer();
                  setResourceProjectRowData(row);
                }}
              >
                {row?.resource_details?.resource_projects.length + " Project"}
              </a>
            ) : (
              "No Project"
            )}
          </>
        </>
      ),
    },
    {
      title: "Start From",
      key: "engaged_from",
      render: (row) => {
        return row?.resource_details?.resource_projects
          ? generateEngagementDates(row?.resource_details?.resource_projects)
              ?.startFromDate
          : "N/A";
      },
    },
    {
      title: "Will Free",
      key: "engaged_to",
      render: (row) => {
        return row?.resource_details?.resource_projects
          ? generateEngagementDates(row?.resource_details?.resource_projects)
              ?.willFreeDate
          : "N/A";
      },
    },
    {
      title: "Block For",
      key: "team_id",
      render: (row) =>
        row?.resource_details?.resource_projects
          ? generateTotalHour(
              generateEngagementDates(row?.resource_details?.resource_projects)
                ?.startFromDate,
              generateEngagementDates(row?.resource_details?.resource_projects)
                ?.willFreeDate
            )
          : "Free",
    },
    {
      title: "Engagement Status",
      key: "engagement_level",
      render: (row) =>
        row?.resource_details?.engagement_level || (
          <Tag color={"warning"}>Free</Tag>
        ),
    },

    !restrictActions
      ? {
          title: "Action",
          key: "action",
          fixed: "right",
          render: (row) => (
            <Space size="middle">
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setDrawerVisible(true);
                  setFlag("Edit");
                  setRowData(row);
                }}
              >
                <FormOutlined className="icon-primary-color" />
              </Button>
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={(e) => {
                  setLoader(true);
                  handleDelete(parseInt(row?.resource_details?.id));
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button danger type="link" style={{ padding: 0 }}>
                  <DeleteOutlined className="icon-secondary-color" />
                </Button>
              </Popconfirm>
            </Space>
          ),
        }
      : {},
  ];

  const projectResourcesColumns = [
    {
      title: "Resource Name",
      key: "name",
      render(row) {
        if (row?.resource_details) {
          return (
            <>
              <div className="resourceInfoContainer">
                <Space>
                  <img
                    className="resourceImage"
                    src={
                      row?.resource_details?.photo
                        ? `${BASE_URL}/${row?.resource_details?.photo}`
                        : "/assets/icons/user_image_1.png"
                    }
                    alt="Resource"
                  />
                  <div className="resourceDetailsContainer">
                    <div className="resourceName">
                      {row?.resource_details?.name || ""}
                    </div>
                    <div className="resourcePositionName">
                      {row?.resource_details?.resource_type_details?.name || ""}
                    </div>
                  </div>
                </Space>
              </div>
            </>
          );
        }
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: ["resource_details", "name"],
    },
    {
      title: "Joining Date",
      key: "joining_date",
      dataIndex: ["resource_details", "created_at"],
      render: (row) => <div>{moment(row).format("DD MMM YYYY")}</div>,
    },
    // {
    //     title: "Total Log Hour",
    //     key: "phone",
    //     dataIndex: "phone",
    // },
    // {
    //     title: "Last Week Progress",
    //     key: "team_id",
    //     render: (row) =>
    //         row?.team_details?.name || (
    //             <Tag color={"warning"}>Not Assigned</Tag>
    //         ),
    // },
    // {
    //     title: "Last Log Entry Date",
    //     key: "team_id",
    //     render: (row) =>
    //         row?.team_details?.name || (
    //             <Tag color={"warning"}>Not Assigned</Tag>
    //         ),
    // },
    {
      title: "Employee ID",
      key: "employee_id",
      dataIndex: ["resource_details", "employee_id"],
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <ContentHeader
            contentTitle={
              projectResourceList ? "Resource List" : "Resource Engagement"
            }
            primaryButtonTitle={!restrictActions ? "Create Resource" : ""}
            primaryButtonClickFunction={projectResourceList ? null : showDrawer}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="card mb-4">
            <CommonPanel headerText="Filter">
              <Form
                form={resourceForm}
                autoComplete="off"
                scrollToFirstError={true}
                layout={"horizontal"}
                onFinish={onFilterFinish}
                style={{ padding: "1rem" }}
              >
                <Row gutter={9}>
                  {/* Order ID */}

                  <Col span={8}>
                    <Form.Item
                      label="Resource Name"
                      name="resource_name"
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        placeholder="Resource Name"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Employee ID"
                      name="resource_employee_id"
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        placeholder="Employee ID"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="resource_team_id"
                      label="Team"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        showSearch
                        placeholder="Select a team"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {teams?.map((e) => (
                          <Option value={e.id} name={e.name} key={e.id}>
                            {e.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* Order ID */}
                  <Col span={8}>
                    <Form.Item
                      label="Engagement Level"
                      name="resource_engagement_level"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        showSearch
                        placeholder="Select Engagement Level"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                      >
                        <Option value="Free" key={3}>
                          Free
                        </Option>
                        <Option value="Shared" key={2}>
                          Shared
                        </Option>
                        <Option value="Dedicated" key={1}>
                          Dedicated
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Number Of Projects"
                      name="num_of_projects"
                      labelCol={{ span: 24 }}
                    >
                      <InputNumber
                        min={1}
                        max={10}
                        placeholder="Number Of Projects"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Skill Filter"
                      name="skill_set"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        mode="multiple"
                        onSearch={onSearch}
                        allowClear
                        placeholder="Select Skill Set"
                        disabled={
                          skillSet && skillSet.length > 0 ? false : true
                        }
                      >
                        {skillSet?.map((e) => (
                          <Option value={e.id} name={e.name} key={e.id}>
                            {e.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* Buttons */}
                  <Col span={4} style={{ marginTop: 40 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Filter
                    </Button>
                  </Col>
                  <Col span={4} style={{ marginTop: 40 }}>
                    <Button
                      type="ghost"
                      onClick={filterReset}
                      style={{ width: "100%" }}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CommonPanel>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ paddingTop: 10 }} className="card mb-4">
            <Card>
              <Table
                scroll={{ x: true }}
                size="middle"
                onChange={(newPage) => {
                  onPageChange(newPage);
                }}
                columns={
                  projectResourceList
                    ? projectResourcesColumns
                    : resourcesColumns
                }
                pagination={true}
                refresh={refresh}
                dataSource={resource || []}
              />
            </Card>
          </div>
        </Col>
      </Row>

      <Drawer
        title="Resource Details"
        placement="right"
        onClose={onClose}
        visible={resourceProjectDrawer}
        width="900"
        refresh={refresh}
      >
        <Text type="secondary">Resource Name</Text>
        {resourceProjectRowData && (
          <>
            <div className="resourceInfoContainer">
              <Space>
                <img
                  className="resourceImage"
                  src={
                    resourceProjectRowData?.resource_details?.photo
                      ? `${BASE_URL}/${resourceProjectRowData?.resource_details?.photo}`
                      : "/assets/icons/user_image_1.png"
                  }
                  alt="Resource"
                />
                <div className="resourceDetailsContainer">
                  <div className="resourceName">
                    {resourceProjectRowData?.resource_details?.name || ""}
                  </div>
                  <div className="resourcePositionName">
                    {resourceProjectRowData?.resource_details
                      ?.resource_type_details?.name || ""}
                  </div>
                </div>
              </Space>
            </div>
          </>
        )}

        <br />

        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "50%",
              height: "100px",
              float: "left",
            }}
          >
            <Text type="secondary">Start From</Text>
            <br />
            {resourceProjectRowData?.resource_details?.resource_projects
              ? generateEngagementDates(
                  resourceProjectRowData?.resource_details?.resource_projects
                )?.startFromDate
              : "N/A"}
          </div>
          <div
            style={{
              marginLeft: "50%",
              height: "100px",
            }}
          >
            <Text type="secondary">Will Free</Text>
            <br />
            {resourceProjectRowData?.resource_details?.resource_projects
              ? generateEngagementDates(
                  resourceProjectRowData?.resource_details?.resource_projects
                )?.willFreeDate
              : "N/A"}
          </div>
        </div>

        <p>
          Project List (
          {resourceProjectRowData?.resource_details?.resource_projects &&
            resourceProjectRowData?.resource_details?.resource_projects?.length}
          )
        </p>
        <Table
          dataSource={
            resourceProjectRowData?.resource_details?.resource_projects
          }
          columns={customColumns}
          refresh={refresh}
        />
      </Drawer>

      <Drawer
        title={flag === "Create" ? "Create Resource" : "Update Resource"}
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        width={600}
        extra={
          <Space>
            <Button onClick={closeDrawer} danger>
              Cancel
            </Button>
            <Button onClick={() => resourceForm.submit()} type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        <ResourceForm
          toggleDrawer={toggleDrawer}
          onFinish={onFinish}
          refetch={refetch}
          rowData={rowData}
          formName={resourceForm}
          visible={drawerVisible}
          flag={flag}
          resetFlagAndRowData={resetFlagAndRowData}
          refresh={refresh}
          setLoader={setLoader}
          setFlag={setFlag}
        />
      </Drawer>
    </>
  );
};

export default ResourceComponent;

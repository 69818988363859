import React, { useEffect } from "react";
import { Row, Col, Form, Input, Switch } from "antd";
import "./ResourceTypeForm.scss";

const ResourceTypeForm = (props) => {
  // Props
  const { onFinish, formName, flag, rowData, visible } = props;

  useEffect(() => {
    if (flag === "Create") {
      formName.setFieldsValue({
        name: null,
        is_active: true,
      });
    }
    if (flag === "Edit") {
      formName.setFieldsValue({
        name: rowData?.name,
        is_active: rowData?.is_active,
      });
    }
  }, [formName, visible, flag, rowData]);
  return (
    <Form layout="vertical" onFinish={onFinish} form={formName}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
          >
            <Input placeholder="Please enter name" />
          </Form.Item>
        </Col>
        <Col span={12} className="statusCheckBox">
          <Form.Item name="is_active" label="Status" valuePropName="checked">
            <Switch
              size={"default"}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ResourceTypeForm;

import { Row, Col, Form, Input, Switch } from "antd";
import "./ProjectTypeForm.scss";
import React, { useEffect } from "react";

const ProjectTypeForm = (props) => {
  // Props
  const { onFinish, formName, flag, rowData, visible } = props;

  useEffect(() => {
    if (flag === "Create") {
      formName.setFieldsValue({
        type_name: null,
        is_active: true,
      });
    }
    if (flag === "Edit") {
      formName.setFieldsValue({
        type_name: rowData?.type_name,
        is_active: rowData?.is_active,
      });
    }

    return () => {
      // componentDidUnmount
      formName.setFieldsValue({});
    }

  }, [formName, visible, flag, rowData]);
  return (
    <Form layout="vertical" onFinish={onFinish} form={formName}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="type_name"
            label="Type Name"
            rules={[
              {
                required: true,
                message: "Please enter type name",
              },
            ]}
          >
            <Input placeholder="Please enter type name" />
          </Form.Item>
        </Col>
        <Col span={12} className="statusCheckBox">
          <Form.Item name="is_active" label="Status" valuePropName="checked">
            <Switch
              size={"default"}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ProjectTypeForm;

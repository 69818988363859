import { useEffect } from 'react';
import {
    Row,
    Col,
    Select,
    Card,
} from "antd";
import { BASE_URL } from "utils/constants";

const ListCard = (props) => {
    // Props
    const {
        cardTitle,
        tableKeyOne,
        tableKeyTwo,
        tableData,
        dataType,
        onChangeMilestoneStatus,
        milestoneStatus,
    } = props;

    // Antd Constants
    const { Option } = Select;

    useEffect(() => {
        let select_parent = document.getElementsByClassName("milestone_status_select");
        
        if(select_parent.length>0) {
            for (var i = 0; i < select_parent.length; i++) {
                let select_selector = select_parent[i].getElementsByClassName("ant-select-selector");
                select_selector[0].style.backgroundColor = tableData[i]?.milestone_status?.color_code;
            }
        }
    }, [tableData]);

    return (
        <Card>
            <Row>
                <Col span={24}>
                    <div className="card-title">{cardTitle || "N/A"}</div>
                </Col>
                <Col span={24}>
                    <div className="table-key">
                        <div>{tableKeyOne || "N/A"}</div>
                        <div>{tableKeyTwo || "N?A"}</div>
                    </div>
                </Col>
                {tableData &&
                    dataType === "milestone" &&
                    tableData.map((item, index) => {
                        return (
                            <Col span={24} key={item?.id}>
                                <div className="table-data">
                                    <div>
                                        <a href="/">
                                            {item?.name || "N/A"}
                                        </a>
                                    </div>
                                    <Select
                                        showSearch
                                        placeholder="Select Milestone Status"
                                        optionFilterProp="children"
                                        onChange={(value) => onChangeMilestoneStatus(value, item?.id)}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .includes(
                                                    input.toLowerCase()
                                                )
                                        }
                                        value={item?.milestone_status?.name}
                                        className="milestone_status_select"
                                        style={{width: "40%"}}
                                        // onSearch={onSearch}
                                    >
                                        {milestoneStatus?.map((e) => (
                                            <Option
                                                value={e.id}
                                                name={e.name}
                                                key={e.id}
                                            >
                                                {e.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Col>
                        );
                    })}
                {tableData &&
                    dataType === "checklist" &&
                    tableData.map((item, index) => {
                        return (
                            <Col span={24} key={item?.id}>
                                <div className="table-data">
                                    <div>
                                        <a href="/">
                                            {item?.title || "N/A"}
                                        </a>
                                    </div>
                                    <div>
                                        <a
                                            href={
                                                `${BASE_URL}/${item?.file_path}` ||
                                                "/"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Attachment
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
            </Row>
        </Card>
    );
};

export default ListCard;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../node_modules/antd/dist/antd.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../node_modules/@ant-design/charts/dist/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-btn-primary {\n  background-color: #517BC3 !important;\n  color: #FFFFFF;\n  border-radius: 3px;\n}\n\n.ant-btn-dangerous {\n  background-color: #FFFFFF;\n  color: #E25E5E;\n  border-radius: 3px;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}", "",{"version":3,"sources":["webpack://./src/styles/antOverrides.scss","webpack://./src/styles/_colors.scss","webpack://./src/styles/index.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,cCDI;EDEJ,kBAAA;AEGJ;;AFAA;EACI,yBCNI;EDOJ,cCNK;EDOL,kBAAA;AEGJ;;AANA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;AAOF;;AAJA;EACE,+EAAA;AAOF","sourcesContent":[".ant-btn-primary {\n    background-color: $primaryColor !important;\n    color: $white;\n    border-radius: 3px;\n}\n\n.ant-btn-dangerous {\n    background-color: $white;\n    color: $danger;\n    border-radius: 3px;\n}","$primaryColor: #517BC3;\n$white: #FFFFFF;\n$danger: #E25E5E;","@import '~antd/dist/antd.css';\n@import \"@ant-design/charts/dist/index.css\";\n@import 'styles/_colors.scss';\n@import 'styles/_mixins.scss';\n@import 'styles/antOverrides.scss';\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

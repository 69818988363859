import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    OverviewTopBar,
    ResourceComponent,
    ProjectHeader,
    ProjectSubHeader,
    ProjectInfoContainer,
    ListCard,
    ProjectNotesComponent,
    OverviewCharts,
} from "components";
import {
    Row,
    Col,
    notification,
    Card,
    Button,
    Dropdown,
    Space,
    Menu,
    Select,
} from "antd";
import { DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import {
    PROJECT_DETAILS_GET,
    MILESTONE_STATUS_LIST,
    PROJECT_STATUS_UPDATE,
    PROJECT_MILESTONE_STATUS_UPDATE,
} from "utils/api";
import { postData, getData } from "utils/api-service";
import "./ProjectOverViewComponent.scss";

const ProjectOverViewComponent = (props) => {
    // States
    const [projectDetailsData, setProjectDetailsData] = useState(null);
    const [milestoneStatus, setMilestoneStatus] = useState();

    // Router
    const { projectId } = useParams();

    const fetchProjectData = useCallback(async () => {
        const projectDetailsQuery = PROJECT_DETAILS_GET();
        const queryWithId = `${projectDetailsQuery}/${projectId}`;
        let projectDetailsRes = projectId ? await getData(queryWithId) : null;
        if (projectDetailsRes && projectDetailsRes.code === 201) {
            setProjectDetailsData(projectDetailsRes.data);
        }
    }, [projectId]);

    const getMilestoneList = useCallback(async () => {
        let milestonequery = MILESTONE_STATUS_LIST();
        let milestoneesponse = await getData(milestonequery);
        if (milestoneesponse && milestoneesponse.code === 200) {
            setMilestoneStatus(milestoneesponse.data.data);
        }
    }, []);

    useEffect(() => {
        if (fetchProjectData) {
            fetchProjectData();
        }
        if (getMilestoneList) {
            getMilestoneList();
        }
    }, [fetchProjectData, getMilestoneList]);

    const onChangeProjectStatus = async (value) => {
        const customQuery = PROJECT_STATUS_UPDATE();
        const bodyObject = {
            project_id: projectDetailsData?.id,
            status_id: value,
        };
        let response = await postData(customQuery, bodyObject);
        if (response && response.code === 201) {
            notification.success({
                message: "Success",
                description: `Project Status Updated Successfully`,
                placement: "topLeft",
            });
            fetchProjectData();
        } else {
            notification.error({
                message: `Project Status Update Failed`,
                placement: "topLeft",
            });
        }
    };

    const onChangeMilestoneStatus = async (value, milestoneId) => {
        const customQuery = PROJECT_MILESTONE_STATUS_UPDATE();
        const bodyObject = {
            project_id: projectDetailsData?.id,
            status_id: value,
            milestone_id: milestoneId,
        };
        let response = await postData(customQuery, bodyObject);
        if (response && response.code === 201) {
            notification.success({
                message: "Success",
                description: `Project Status Updated Successfully`,
                placement: "topLeft",
            });
            fetchProjectData();
        } else {
            notification.error({
                message: `Project Status Update Failed`,
                placement: "topLeft",
            });
        }
    };

    return (
        <>
            <OverviewTopBar />
            <OverviewCharts projectDetailsData={projectDetailsData} />
            <Card>
                <ProjectHeader
                    projectName={projectDetailsData?.name}
                    projectId={projectId}
                />
                <ProjectSubHeader
                    projectStatusName={projectDetailsData?.project_status?.id}
                    projectDetailsData={projectDetailsData}
                    onChangeProjectStatus={onChangeProjectStatus}
                    milestoneStatus={milestoneStatus}
                />
                <ProjectInfoContainer projectDetailsData={projectDetailsData} />
            </Card>
            <Row
                className="overview-list-card"
                gutter={8}
                style={{ marginTop: 8, marginBottom: 8 }}
            >
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <ListCard
                        cardTitle="Project Milestone"
                        tableKeyOne="Milestone Name"
                        tableKeyTwo="Status"
                        tableData={projectDetailsData?.project_milestones}
                        dataType="milestone"
                        onChangeMilestoneStatus={onChangeMilestoneStatus}
                        milestoneStatus={milestoneStatus}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <ListCard
                        cardTitle="Check List"
                        tableKeyOne="Checklist Name"
                        tableKeyTwo="Attachment"
                        tableData={projectDetailsData?.project_checklists}
                        dataType="checklist"
                        onChangeMilestoneStatus={onChangeMilestoneStatus}
                        milestoneStatus={milestoneStatus}
                    />
                </Col>
            </Row>
            <ResourceComponent
                projectResourceList={projectDetailsData?.project_resources}
                comingFrom="project_overview"
            />
            <ProjectNotesComponent
                fetchProjectData={fetchProjectData}
                projectDetailsData={projectDetailsData}
            />
        </>
    );
};

export default ProjectOverViewComponent;

import { useEffect } from 'react';
import { Row, Col, Select } from "antd";
import { ResourceCard } from "components";
import { getResourceByType } from "utils/functions";

const ProjectSubHeader = (props) => {
    // Props
    const {
        projectStatusName,
        projectDetailsData,
        onChangeProjectStatus,
        milestoneStatus,
    } = props;

    // Antd Constants
    const { Option } = Select;

    useEffect(() => {
        let select_parent = document.getElementsByClassName("project_status_select");
        
        if(select_parent.length>0) {
            for (var i = 0; i < select_parent.length; i++) {
                let select_selector = select_parent[i].getElementsByClassName("ant-select-selector");
                select_selector[0].style.backgroundColor = projectDetailsData?.project_status?.color_code;
            }
        }
    }, [projectDetailsData]);


    return (
        <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="overview-title-small">PMO Name</div>
                <ResourceCard
                    item={getResourceByType(
                        projectDetailsData?.project_resources,
                        "PMO"
                    )}
                />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="overview-title-small">KAM Name</div>
                <ResourceCard
                    item={getResourceByType(
                        projectDetailsData?.project_resources,
                        "KAM"
                    )}
                />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="overview-title-small">Project Status</div>
                <Select
                    showSearch
                    placeholder="Select status"
                    optionFilterProp="children"
                    onChange={onChangeProjectStatus}
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    value={projectStatusName}
                    className="project_status_select"
                    style={{width: "45%"}}
                >
                    {milestoneStatus?.map((e) => (
                        <Option value={e.id} name={e.name} key={e.id}>
                            {e.name}
                        </Option>
                    ))}
                </Select>
            </Col>
        </Row>
    );
};

export default ProjectSubHeader;

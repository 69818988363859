import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Space,
  Card,
  Typography,
  Button,
  Drawer,
  Form,
  notification,
  Popconfirm,
  Table,
  DatePicker,
  Input,
  Select,
} from "antd";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { ContentHeader, MilestoneStatusForm } from "components";
import "./ActivityLogComponent.scss";
import {
  MILESTONE_STATUS_CREATE,
  ACTIVITY_LOGS_LIST,
  PROJECTS_LIST_ALL,
  USER_LIST_ALL,
  MILESTONE_STATUS_DELETE,
  MILESTONE_STATUS_UPDATE,
} from "../../utils/api";
import { postData, getData } from "../../utils/api-service";
import { Interweave } from "interweave";
import CommonPanel from "../../components/common/CommonPanel";
const { RangePicker } = DatePicker;

// Antd Constants
const { Title } = Typography;

const ActivityLogComponent = (props) => {
  const { setParentCurrentPage } = props;
  // States
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [flag, setFlag] = useState("");
  const [rowData, setRowData] = useState(null);
  const [activityLogs, setActivityLogs] = useState();
  const [refresh, setRefresh] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [page, setPage] = useState(1);
  const [activityLogForm] = Form.useForm();
  const [projects, setProjects] = useState();
  const [users, setUser] = useState();
  const [filterQueryData, setFilterQueryData] = useState({});

  const onChange = (value) => {};

  const onSearch = (value) => {};

  const { Option } = Select;
  // Antd
  const [milestoneStatusForm] = Form.useForm();

  const refetch = () => {
    setRefresh(refresh + 1);
  };
  const [loader, setLoader] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
    setFlag("Create");
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const filterReset = () => {
    activityLogForm.resetFields();
    setFilterQueryData({});
    refetch();
  };

  const submitMilestoneStatusForm = async (values) => {
    if (flag === "Create") {
      let requestBody = {};
      requestBody.name = values.name || null;
      requestBody.is_active = values.is_active ? values.is_active : false;
      requestBody.color_code = values?.color_code || null;
      let query = MILESTONE_STATUS_CREATE();
      let response = await postData(query, requestBody);
      if (response && response.code === 201) {
        closeDrawer();
        notification.success({
          message: "Success",
          description: `${"Saved successfully"}`,
          placement: "topLeft",
        });

        setLoader(false);
        refetch();
      } else {
        notification.error({
          message: "Error",
          placement: "topLeft",
        });
      }
    }

    if (flag === "Edit") {
      let requestBody = {};
      requestBody.name = values.name;
      requestBody.is_active = values.is_active ? values.is_active : false;
      requestBody.color_code = values?.color_code || null;
      let query = MILESTONE_STATUS_UPDATE() + "/" + parseInt(rowData?.id);
      let response = await postData(query, requestBody);
      if (response && response.code === 201) {
        closeDrawer();
        notification.success({
          message: "Success",
          description: `${"Updated successfully"}`,
          placement: "topLeft",
        });

        setLoader(false);
        refetch();
      } else {
        notification.error({
          message: "Error",
          placement: "topLeft",
        });
      }
    }
  };

  const resetFlagAndRowData = () => {
    setFlag("");
    setRowData(null);
  };

  const cancel = (e) => {};

  const handleDelete = async (e) => {
    let query = MILESTONE_STATUS_DELETE() + "/" + e.variables.deleteTypeId;
    let response = await postData(query);
    if (response && response.code === 200) {
      closeDrawer();
      notification.success({
        message: "Success",
        description: `${"MIlestone Status Deleted Successfully"}`,
      });

      setLoader(false);
    } else {
      notification.error({
        message: "Error",
        placement: "topLeft",
      });
    }

    refetch();
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let page = currentPage;
      let query = ACTIVITY_LOGS_LIST();
      let response = await getData(query, filterQueryData);

      if (response && response.code === 200) {
        setActivityLogs(response.data);
        setPageCount("pageCount", response.data.total);
        setLoader(false);
      } else {
        notification.error({
          message: "Error getting data",
          placement: "topLeft",
        });

        setLoader(false);
      }

      let query_project = PROJECTS_LIST_ALL();
      let query_project_response = await getData(query_project);
      console.log("asas", query_project_response);

      if (query_project_response && query_project_response.code === 200) {
        setProjects(query_project_response.data);
        // setPageCount("pageCount", response?.data?.total);
        setLoader(false);
      } else {
        notification.error({
          message: "Error getting data",
          placement: "topLeft",
        });

        setLoader(false);
      }

      let query_users = USER_LIST_ALL();
      let query_users_response = await getData(query_users);
      console.log("asas", query_users_response);

      if (query_users_response && query_users_response.code === 200) {
        setUser(query_users_response.data);
        // setPageCount("pageCount", response?.data?.total);
        setLoader(false);
      } else {
        notification.error({
          message: "Error getting data",
          placement: "topLeft",
        });

        setLoader(false);
      }
    };

    // call the function
    fetchData();
    // make sure to catch any error
  }, [refresh]);

  const onFinish = (values) => {
    let filterQueryObject = {
      ...(values?.user_id && {
        user_id: values.user_id,
      }),
      ...(values?.project_id && {
        project_id: values.project_id,
      }),

      ...(values?.dates && {
        start_date: values?.dates[1].format("YYYY-MM-DD HH:mm:ss"),
      }),
      ...(values?.dates && {
        end_date: values?.dates[0].format("YYYY-MM-DD HH:mm:ss"),
      }),
    };

    setFilterQueryData(filterQueryObject);
    refetch();
  };

  const setCurrentPageToStates = (page) => {
    setCurrentPage(page);

    if (setParentCurrentPage) {
      setParentCurrentPage(page);
    }
  };

  const paginate = (page) => {
    setCurrentPageToStates(page);
  };

  const sizeChange = (currentPage, size) => {
    setCurrentPageToStates(currentPage);
    setPerPageCount(size);
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "Project",
      key: "project_details",
      render: (row) => <> {row?.project_details?.name || "N/A"}</>,
    },
    {
      title: "Operation Name",
      key: "operation_name",
      dataIndex: "operation_name",
    },
    {
      title: "Details",
      key: "logs_message",
      dataIndex: "logs_message",
      render: (row) => {
        return <Interweave content={row} />;
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <ContentHeader contentTitle="Project Activity Logs" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="card mb-4">
            <CommonPanel defaultActiveKey="1" headerText="Filter">
              <Form
                form={activityLogForm}
                autoComplete="off"
                scrollToFirstError={true}
                layout={"horizontal"}
                onFinish={onFinish}
                style={{ padding: "1rem" }}
              >
                <Row gutter={5}>
                  {/* Customer Phone */}
                  <Col span={5}>
                    <Form.Item
                      name="user_id"
                      label="User"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        showSearch
                        placeholder="Select User"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {users?.map((e) => (
                          <Option value={e.id} name={e.name} key={e.id}>
                            {e.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* Customer Phone */}
                  <Col span={5}>
                    <Form.Item
                      name="project_id"
                      label="Project"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        showSearch
                        placeholder="Select Project"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                      >
                        {projects?.map((e) => (
                          <Option value={e.id} name={e.name} key={e.id}>
                            {e.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* Start and End Date */}
                  <Col span={8}>
                    <Form.Item
                      label="Date Range Filter"
                      name="dates"
                      labelCol={{ span: 24 }}
                    >
                      <RangePicker
                        format={"YY-MM-DD HH:mm:ss"}
                        bordered={true}
                        style={{ display: "flex" }}
                        showTime={false}
                      />
                    </Form.Item>
                  </Col>

                  {/* Buttons */}
                  <Col span={3} style={{ marginTop: 40 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Filter
                    </Button>
                  </Col>
                  <Col span={3} style={{ marginTop: 40 }}>
                    <Button
                      type="ghost"
                      onClick={filterReset}
                      style={{ width: "100%" }}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CommonPanel>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ paddingTop: 10 }} className="card mb-4">
            <Card>
              <Table
                scroll={{ x: true }}
                size="middle"
                onChange={(newPage) => {
                  onPageChange(newPage);
                }}
                columns={columns}
                refresh={refresh}
                pagination={true}
                dataSource={activityLogs || []}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ActivityLogComponent;

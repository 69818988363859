import { useNavigate } from "react-router-dom";
import {Row, Col, Space, Button, } from 'antd';
import { StarOutlined } from '@ant-design/icons';

const ProjectHeader = (props) => {
    // Props
    const {projectName, buttonName, projectId} = props;

    // Router
    const navigate = useNavigate();

    const goToRoute = (routeName) => {
        navigate(routeName);
    };
    
    return (
        <>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="overview-title-small">
                        Project Name:{" "}
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="overview-name-header">
                        <div className="name-container">
                            <Space>
                                <div>
                                    <StarOutlined />
                                </div>
                                <div className="project-name-text">
                                    {projectName || ""}
                                </div>
                            </Space>
                        </div>
                        <div>
                            <Button
                                onClick={() =>
                                    goToRoute(`/project/${projectId}`)
                                }
                            >
                                {buttonName ? buttonName : `Edit Project`}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ProjectHeader;
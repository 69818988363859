import { createContext, useEffect, useState } from "react";
import cookies from "js-cookie";
import {
  ProjectOutlined,
  UserOutlined,
  BuildOutlined,
  ShrinkOutlined,
  TeamOutlined,
  UngroupOutlined,
  BorderOuterOutlined,
  BlockOutlined,
  MoneyCollectOutlined,
  CustomerServiceOutlined,
  GithubOutlined,
  FullscreenExitOutlined,
  SettingOutlined,
  FireOutlined,
} from "@ant-design/icons";

export const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  // States
  const [auth, setAuth] = useState(null);
  const [initialLogin, setInitialLogin] = useState(false);
  const [authLoading, setAuthLoading] = useState(
    !initialLogin && cookies.get("jmd_user_data") ? true : false
  );

  useEffect(() => {
    if (initialLogin) {
      cookies.set("jmd_user_data", JSON.stringify(auth), {
        expires: 14,
      });
      setInitialLogin(false);
      setAuthLoading(false);
    }
    if (!initialLogin && cookies.get("jmd_user_data")) {
      const userData = JSON.parse(cookies.get("jmd_user_data"));
      setAuth(userData || {});
      setAuthLoading(false);
    }
  }, [initialLogin]);

  const logout = () => {
    cookies.remove("jmd_user_data");
    setAuth(null);
  };

  const menuItems = [
    {
      id: 1,
      key: "1",
      icon: <BorderOuterOutlined />,
      label: "Jira Dashboard",
      to: "/",
    },
    {
      id: 2,
      key: "2",
      icon: <BlockOutlined />,
      label: "Projects",
      to: "/projects",
    },
    {
      id: 9,
      key: "9",
      icon: <UserOutlined />,
      label: "Resource",
      to: "/resource",
    },
    {
      id: 19,
      key: "19",
      icon: <SettingOutlined />,
      label: "Configuration",
      // to: "/project-type",
      children: [
        {
          id: 3,
          key: "3",
          icon: <ProjectOutlined />,
          label: "Project Type",
          to: "/project-type",
        },
        {
          id: 4,
          key: "4",
          icon: <MoneyCollectOutlined />,
          label: "Charge Type",
          to: "/charge-type",
        },
        {
          id: 5,
          key: "5",
          icon: <BuildOutlined />,
          label: "Business Unit",
          to: "/business-unit",
        },
        {
          id: 6,
          key: "6",
          icon: <CustomerServiceOutlined />,
          label: "Support Status",
          to: "/support-status",
        },
        {
          id: 7,
          key: "7",
          icon: <ShrinkOutlined />,
          label: "Milestone Status",
          to: "/milestone-status",
        },
        {
          id: 8,
          key: "8",
          icon: <TeamOutlined />,
          label: "Team",
          to: "/team",
        },

        {
          id: 10,
          key: "10",
          icon: <UngroupOutlined />,
          label: "Resource Type",
          to: "/resource-type",
        },
        {
          id: 11,
          key: "11",
          icon: <FireOutlined />,
          label: "Skill Set",
          to: "/skill-set",
        },
      ],
    },
    {
      id: 16,
      key: "16",
      icon: <FullscreenExitOutlined />,
      label: "Activity Logs",
      to: "/activity-logs",
    },
  ];

  return (
    <AuthContext.Provider
      value={{
        menuItems,
        auth,
        setAuth,
        setInitialLogin,
        authLoading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

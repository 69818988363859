import {
  Row,
  Col,
  Form,
  Input,
  Switch,
  message,
  Upload,
  Select,
  Space,
  DatePicker,
} from "antd";
import "./ResourceForm.scss";
import React, { useState, useEffect } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  TEAMS_LIST,
  RESOURCE_TYPE_LIST,
  FILE_UPLAOD,
  SKILL_SET_LIST,
} from "../../../utils/api";
import { postData, getData } from "../../../utils/api-service";
import { BASE_URL, FILE_UPLOAD_PATH } from "../../../utils/constants";
const { Option } = Select;

const { RangePicker } = DatePicker;

const ResourceForm = (props) => {
  // Props
  const { onFinish, formName, flag, rowData, visible } = props;
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [teams, setTeams] = useState();
  const [refresh, setRefresh] = useState(0);
  const [resourceTypes, setResourceTypes] = useState(null);
  const [skillSet, setSkillSet] = useState(null);
  const onChange = (value) => {};

  const onSearch = (value) => {};

  useEffect(() => {
    if (flag === "Create") {
      formName.setFieldsValue({
        employee_id: null,
        email: null,
        name: null,
        phone: null,
        team_id: null,
        cost_per_hour: null,
        is_active: true,
        photo: null,
        resource_type_id: null,
        engagement_level: null,
      });
    }
    if (flag === "Edit") {
      const skillSet = [];
      rowData?.resource_details?.resource_skills &&
        rowData?.resource_details?.resource_skills.length > 0 &&
        rowData?.resource_details?.resource_skills.forEach((skill, index) => {
          skillSet.push(skill.id);
        });

      formName.setFieldsValue({
        employee_id: rowData?.resource_details?.employee_id,
        email: rowData?.resource_details?.email,
        name: rowData?.resource_details?.name,
        phone: rowData?.resource_details?.phone,
        team_id: rowData?.resource_details?.team_id,
        photo: rowData?.resource_details?.photo,
        cost_per_hour: rowData?.resource_details?.cost_per_hour,
        is_active: rowData?.resource_details?.is_active,
        resource_type_id: rowData?.resource_details?.resource_type_id,
        engagement_level: rowData?.resource_details?.engagement_level,
        assigned_skill: skillSet,
      });
    }

    return () => {
      // componentDidUnmount
      formName.setFieldsValue({});
      setPhoto(null);
    };
  }, [formName, visible, flag, rowData]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setPhoto(url);
      });
    }
  };

  const exEndDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      expected_end_date: dateString,
    });
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let query = TEAMS_LIST();
      let query2 = RESOURCE_TYPE_LIST();
      let skillQuery = SKILL_SET_LIST();
      let response = await getData(query);
      let response2 = await getData(query2);
      let skillResponse = await getData(skillQuery);
      if (response && response.code === 200) {
        setTeams(response.data);
      }
      if (response2 && response2.code === 200) {
        setResourceTypes(response2.data.data);
      }
      if (skillResponse && skillResponse.code === 200) {
        setSkillSet(skillResponse.data.data);
      }
    };

    // call the function
    fetchData();
    // make sure to catch any error
  }, []);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const doRenderImageComponent = (sendImageData) => {
    if (sendImageData?.resource_details?.photo) {
      return (
        <Form.Item name="photo" style={{ marginTop: 10 }}>
          <Upload
            action={`${BASE_URL}/${FILE_UPLOAD_PATH}`}
            accept="image/jpeg, image/jpg, image/png, .jpg, .jpeg, .png, .svg"
            defaultFileList={[
              {
                status: "done",
                url: BASE_URL + `/${sendImageData?.resource_details?.photo}`,
              },
            ]}
            showUploadList={{ showPreviewIcon: false }}
            listType="picture-card"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            maxCount={1}
          >
            {"+ Upload"}
          </Upload>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item name="photo" style={{ marginTop: 10 }}>
          <Upload
            action={`${BASE_URL}/${FILE_UPLOAD_PATH}`}
            accept="image/jpeg, image/png, .svg"
            showUploadList={{ showPreviewIcon: false }}
            listType="picture-card"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            maxCount={1}
          >
            {"+ Upload"}
          </Upload>
        </Form.Item>
      );
    }
  };

  const children = [];

  skillSet &&
    skillSet.length > 0 &&
    skillSet.forEach((skill, index) => {
      children.push(
        <Option value={skill.id} name={skill.name} key={skill.id}>
          {skill.name}
        </Option>
      );
    });

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={formName}
      initialValues={{ is_active: true }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="employee_id"
            label="Employee ID"
            rules={[
              {
                required: true,
                message: "Please enter Emlpoyee ID",
              },
            ]}
          >
            <Input placeholder="Please enter Emlpoyee ID" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter Email",
              },
              {
                pattern: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
                message: "Not a valid email address",
              },
            ]}
          >
            <Input placeholder="Please enter Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter Name",
              },
            ]}
          >
            <Input placeholder="Please enter  Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone" label="Phone">
            <Input placeholder="Please enter Phone" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="team_id" label="Team">
            <Select
              showSearch
              placeholder="Select a team"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {teams?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="cost_per_hour" label="Cost Per Hour">
            <Input placeholder="Please enter Cost Per Hour" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="resource_type_id" label="Resource Type">
            <Select
              showSearch
              placeholder="Select a resource type"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {resourceTypes?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="engagement_level" label="Enagement Level">
            <Select
              showSearch
              placeholder="Select Engagement Level"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
            >
              <Option value="Free" key={3}>
                Free
              </Option>
              <Option value="Shared" key={2}>
                Shared
              </Option>
              <Option value="Dedicated" key={1}>
                Dedicated
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Assign Resource Skillset"
            name="assigned_skill"
            labelCol={{ span: 24 }}
          >
            <Select
              mode="multiple"
              onSearch={onSearch}
              allowClear
              placeholder="Select Skill Set"
              disabled={skillSet && skillSet.length > 0 ? false : true}
            >
              {skillSet?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          {flag === "Edit" ? doRenderImageComponent(rowData) : null}

          {flag === "Create" ? (
            <Form.Item name="photo" label="Photo">
              <Upload
                name="photo"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${BASE_URL}/${FILE_UPLOAD_PATH}`}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {photo ? (
                  <img
                    src={photo}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          ) : null}
        </Col>

        <Col span={12} className="statusCheckBox">
          <Form.Item name="is_active" label="Status" valuePropName="checked">
            <Switch
              size={"default"}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ResourceForm;

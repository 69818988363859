import { Row, Col, Form, Input, Select, DatePicker, InputNumber } from "antd";
import "./ProjectInfoComponent.scss";
import { formatDate } from "utils/functions";

const ProjectInfoComponent = (props) => {
  // Props
  const {
    onFinish,
    formName,
    projectTypes,
    businessUnits,
    chargeTypes,
    supportStatus,
    milestoneStatus,
  } = props;

  // Antd Constants
  const { Option } = Select;

  const FormSubHeader = ({ subHeaderName }) => {
    return <div className="form-sub-header">{subHeaderName}</div>;
  };

  const onChange = (value) => {};

  const onSearch = (value) => {};

  const woStartaDteRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      wo_start_date: formatDate(dateString),
    });
  };

  const woEndDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      wo_end_date: formatDate(dateString),
    });
  };

  const exEndDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      expected_end_date: formatDate(dateString),
    });
  };

  const satrtDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      start_date: formatDate(dateString),
    });
  };

  const recPerDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      recurring_period_start_date: formatDate(dateString),
    });
  };

  const recPerEndDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      recurring_period_end_date: formatDate(dateString),
    });
  };

  return (
    <Form
      className="projectForm"
      layout="vertical"
      onFinish={onFinish}
      form={formName}
    >
      <FormSubHeader subHeaderName="Project Information" />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="jira_key"
            label="Jira Key"
            rules={[
              {
                required: true,
                message: "Please enter Jira Key",
              },
            ]}
          >
            <Input placeholder="Please enter Jira Key" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="project_code"
            label="Project Code"
            rules={[
              {
                required: true,
                message: "Please enter Project Code",
              },
            ]}
          >
            <Input placeholder="Please enter Project Code" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Project Name"
            rules={[
              {
                required: true,
                message: "Please enter Project Name",
              },
            ]}
          >
            <Input placeholder="Please enter Project Name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="project_type_id"
            label="Project Type"
            rules={[
              {
                required: true,
                message: "Please select Project Type",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select project type"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {projectTypes?.map((e) => (
                <Option value={e.id} name={e.type_name} key={e.id}>
                  {e.type_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="business_unit_id"
            label="Business Unit Name"
            rules={[
              {
                required: true,
                message: "Please select Business Unit",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select Business Unit"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {businessUnits?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <FormSubHeader subHeaderName="Project Duration" />
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="wo_ref" label="WO Ref">
            <Input placeholder="Please enter WO Ref" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="wo_start_date" label="WO Start Date">
            <DatePicker
              format={"YYYY-MM-DD HH:mm:ss"}
              showTime
              onChange={woStartaDteRangeChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="wo_end_date" label="WO End Date">
            <DatePicker
              showTime
              onChange={woEndDateRangeChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="start_date" label="Project Start Date">
            <DatePicker
              showTime
              onChange={satrtDateRangeChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="expected_end_date" label="Project Expected End Date">
            <DatePicker
              showTime
              onChange={exEndDateRangeChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="expected_hour" label="Estimated Project Hour">
            <Input placeholder="Please enter Hour" />
          </Form.Item>
        </Col>
      </Row>
      <FormSubHeader subHeaderName="Project Option" />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="charge_type_id"
            label="Charge Type"
            rules={[
              {
                required: true,
                message: "Please select project status",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select status"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {chargeTypes?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="support_status_id"
            label="Support Status"
            rules={[
              {
                required: true,
                message: "Please select support status",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select status"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {supportStatus?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="otc" label="OTC">
            <InputNumber
              placeholder="Please enter OTC"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="mrc" label="MRC">
            <InputNumber
              placeholder="Please enter MRC"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="amc" label="AMC">
            <InputNumber
              placeholder="Please enter AMC"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="recurring_period_start_date"
            label="Recurring Period Start Date"
          >
            <DatePicker
              showTime
              onChange={recPerDateRangeChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="recurring_period_end_date"
            label="Recurring Period End Date"
          >
            <DatePicker
              showTime
              onChange={recPerEndDateRangeChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="project_status"
            label="Project Status"
            rules={[
              {
                required: true,
                message: "Please select project status",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select status"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {milestoneStatus?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ProjectInfoComponent;

import { Row, Col, Space, Card, Typography, Button } from "antd";
import "./ContentHeader.scss";

const ContentHeader = (props) => {
    // Props
    const {
        contentTitle,
        primaryButtonTitle,
        primaryButtonClickFunction,
        primaryButtonSize,
        secondaryButtonTitle,
        secondaryButtonClickFunction,
        secondaryButtonSize,
        titleFontSize,
    } = props;

    // Dynamic Styles
    const titleStyle = {
        fontSize: titleFontSize ? titleFontSize : '18px', 
    }

    return (
        <Card className="contentHeader">
            <div className="contentTitle">
                <div className="contentName" style={titleStyle}>
                    {contentTitle}
                </div>
                <Space>
                    {secondaryButtonTitle && (
                        <Button
                            onClick={secondaryButtonClickFunction}
                            type="primary"
                            size={secondaryButtonSize || "middle"}
                        >
                            {secondaryButtonTitle}
                        </Button>
                    )}
                    {primaryButtonTitle && (
                        <Button
                            onClick={primaryButtonClickFunction}
                            type="primary"
                            size={primaryButtonSize || "middle"}
                        >
                            {primaryButtonTitle}
                        </Button>
                    )}
                </Space>
            </div>
        </Card>
    );
};

export default ContentHeader;

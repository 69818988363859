import { useEffect, useState, useCallback } from "react";
import {
    Card,
    Button,
    Modal,
    Form,
    Row,
    Col,
    Input,
    notification,
    Upload,
    message,
    Select,
} from "antd";
import { ContentHeader } from "components";
import "./ChecklistComponent.scss";
import {
    DeleteTwoTone,
    ExclamationCircleOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    PROJECT_CHECKLIST_GET,
    PROJECT_CHECKLIST_CREATE,
    PROJECT_CHECKLIST_DELETE,
    PROJECT_CHECKLIST_REMOVEALL,
    FILE_UPLAOD,
    GET_ATTACHMENT_TYPES,
    PROJECT_DETAILS_GET,
} from "utils/api";
import { postData, getData } from "utils/api-service";
import { getDocumentName } from "utils/functions";

const ChecklistComponent = (props) => {
    // Props
    const { currentProjectId, projectDetailsData } = props;

    // States
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checklist, setChecklist] = useState(null);
    const [attachmentTypes, setAttachmentTypes] = useState(null);

    // Antd
    const [checklistForm] = Form.useForm();
    const { confirm } = Modal;
    const { Option } = Select;
    const gridStyle = {
        width: '25%',
        textAlign: 'center',
    };

    const getChecklist = useCallback(async () => {
        const projectDetailsQuery = PROJECT_DETAILS_GET();
        const queryWithId = `${projectDetailsQuery}/${currentProjectId}`;
        let projectDetailsRes = currentProjectId
            ? await getData(queryWithId)
            : null;
        if (projectDetailsRes && projectDetailsRes.code === 201) {
            setChecklist(projectDetailsRes?.data?.checklist);
        } else {
            notification.error({
                message: "Error",
                description: `Error Getting Checklist`,
                placement: "topLeft",
            });
        }
    }, [currentProjectId]);

    const getAttachmentTypes = useCallback(async() => {
        const getChecklistQuery = GET_ATTACHMENT_TYPES();
        const response = await getData(getChecklistQuery);
        if (response && response?.code === 200) {
            setAttachmentTypes(response?.data);
        } else {
            notification.error({
                message: "Error",
                description: `Error Getting Attachment Types`,
                placement: "topLeft",
            });
        }
    }, []);

    useEffect(() => {
        if (currentProjectId) {
            getChecklist();
            getAttachmentTypes();
        }
    }, [currentProjectId, getAttachmentTypes, getChecklist]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        checklistForm.resetFields();
        setIsModalOpen(false);
    };

    const handleOk = () => {
        checklistForm.submit();
        setIsModalOpen(false);
    };

    const deleteConfirm = (id, deleteFunction) => {
        confirm({
            title: "Are you sure you want to remove?",
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",

            onOk() {
                deleteFunction(id);
            },

            onCancel() {
                return null;
            },
        });
    };

    const deleteChecklist = async (checklistId) => {
        const deleteQuery = PROJECT_CHECKLIST_DELETE();
        const customQuery = `${deleteQuery}`;
        const bodyObject = {
          "checklist_id": checklistId,
          "project_id": currentProjectId,
        };
        const response = await postData(customQuery, bodyObject);

        if (response && response.code === 201) {
            notification.success({
                message: "Success",
                description: `Checklist Deleted Successfully`,
                placement: "topLeft",
            });
            getChecklist();
        } else {
            notification.error({
                message: "Error",
                description: `Error Deleting Checklist`,
                placement: "topLeft",
            });
        }
    };

    const ChecklistCard = ({ checklistId, checklistName, checklistImage, documentType }) => {
        const splittedList = checklistImage ? checklistImage.split("/") : null;
        const lastFileName = splittedList ? splittedList[splittedList?.length-1] : null;
        
        return (
            <Card size="small" className="milestoneCard" key={checklistId}>
                <div className="milestoneListHeader">
                    <div>
                        <div className="milestoneName"><b>{checklistName}</b></div>
                        <div>{lastFileName || ""}</div>
                    </div>
                    <div>
                        <DeleteTwoTone
                            onClick={() => deleteConfirm(checklistId, deleteChecklist)}
                        />
                    </div>
                </div>
            </Card>
        );
    };

    const onFinish = async (values) => {
        // console.log("onfinish values: ", values);

        const formObject = {
            project_id: currentProjectId,
            title: values?.title,
            file: values?.file?.file?.response,
            document_type: values?.document_type,
        };

        if (!formObject.project_id) {
            notification.error({
                message: `No Project Found! Please Create Project First`,
                placement: "topLeft",
            });
            return null;
        }

        const checklistCreateQuery = PROJECT_CHECKLIST_CREATE();
        const response = await postData(checklistCreateQuery, formObject);
        if (response && response.code === 201) {
            notification.success({
                message: "Success",
                description: `Checklist Created Successfully`,
                placement: "topLeft",
            });
            getChecklist();
            checklistForm.resetFields();
        } else {
            notification.error({
                message: `Checklist Create Failed`,
                placement: "topLeft",
            });
        }
    };


    const removeAllChecklist = async(currentProjectId) => {
        const removeAllQuery = PROJECT_CHECKLIST_REMOVEALL();
        const customQuery = `${removeAllQuery}/${currentProjectId}`;
        const response = await postData(customQuery);

        if (response && response.code === 200) {
            notification.success({
                message: "Success",
                description: `All Checklists Removed Successfully`,
                placement: "topLeft",
            });
            getChecklist();
        } else {
            notification.error({
                message: "Error",
                description: `Error Removing All Checklists`,
                placement: "topLeft",
            });
        }
    }

    const handleFileChange = (info) => {
        if (info.file.status === "uploading") {
            return;
        }

        if (info.file.status === "error") {
            message.error("Error uploading file!");
            return;
        }

        if (info.file.status === "done") {
            message.success("File uploaded successfully!");
            return;
        }
    };

    const customUploadRequest = async ({ file, onSuccess, onError }) => {
        const query = FILE_UPLAOD();
        const formData = new FormData();
        formData.append("file", file);

        const response = await postData(query, formData);

        if (response) {
            onSuccess(response[0]);
            return;
        } else {
            onError("error");
            return;
        }
    };

    return (
        <>
            <Card className="milestoneContainer">
                <ContentHeader
                    contentTitle={"Checklist"}
                    primaryButtonTitle={"Create New Checklist"}
                    primaryButtonClickFunction={showModal}
                    titleFontSize="14px"
                    primaryButtonSize="small"
                />
                <div className="milestoneListHeader">
                    <div></div>
                    <div>
                        <Button
                            type="link"
                            onClick={() =>
                                deleteConfirm(
                                    currentProjectId,
                                    removeAllChecklist
                                )
                            }
                            disabled={
                                checklist && checklist.length > 0 ? false : true
                            }
                        >
                            Delete All
                        </Button>
                    </div>
                </div>
                <div className="milestoneListContainer">
                    {checklist &&
                        checklist.map((item, index) => {
                            return (
                                <>
                                    <Card title={getDocumentName(item?.code)} style={{marginBottom: 20}}>
                                        {item?.data?.length > 0 ?
                                            item?.data?.map(
                                                (subItem, subItemIndex) => {
                                                    return (
                                                        <p>
                                                            <ChecklistCard
                                                                checklistId={
                                                                    subItem?.id
                                                                }
                                                                checklistName={
                                                                    subItem?.title
                                                                }
                                                                checklistImage={
                                                                    subItem?.file_path
                                                                }
                                                                key={
                                                                    subItem?.id
                                                                }
                                                                documentType={
                                                                    subItem?.document_type
                                                                }
                                                            />
                                                        </p>
                                                    );
                                                }
                                            ) : "No items in this checklist section"}
                                    </Card>
                                </>
                            );
                        })}
                </div>
            </Card>
            <Modal
                title="Checklist Form"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    className="checklistForm"
                    layout="vertical"
                    onFinish={onFinish}
                    form={checklistForm}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="Checklist Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter Checklist Name",
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter Checklist Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="document_type"
                                label="Document Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter Document Type",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Type"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    style={{ width: "90%" }}
                                    // value={row?.milestone_status?.name || "N/A"}
                                    // onChange={(event) =>
                                    //     onMilestoneStatusChange(event, row)
                                    // }
                                    // onSearch={onSearch}
                                >
                                    {attachmentTypes?.map((item, index) => (
                                        <Option
                                            value={item?.value}
                                            name={item?.value}
                                            key={index}
                                        >
                                            {item?.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="file"
                                label="Attachment"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter Checklist Name",
                                    },
                                ]}
                            >
                                <Upload
                                    accept="image/jpeg, image/png, image/jpg, .svg, .jpg, .doc, .docx, .txt, .pdf"
                                    maxCount={1}
                                    onChange={handleFileChange}
                                    customRequest={customUploadRequest}
                                    className="checklist-upload"
                                    // listType="picture-card"
                                    // showUploadList={{ showPreviewIcon: false }}
                                >
                                    <Button
                                        icon={<UploadOutlined />}
                                        style={{ width: "100%" }}
                                    >
                                        Click to Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ChecklistComponent;

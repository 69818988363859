import { useEffect, useState } from "react";
import { CommentNote } from 'components';

const NoteList = (props) => {
    // Props
    const { projectDetailsData } = props;

    // States
    const [totalNotes, setTotalNotes] = useState(null);
    const [expandList, setExpandList] = useState(false);
    const [sliceNumber, setSliceNumber] = useState(5);


    useEffect(() => {
        const totalNotesCount = Array.isArray(projectDetailsData?.project_notes) && projectDetailsData?.project_notes?.length;
        const isExpandable = Array.isArray(projectDetailsData?.project_notes) && projectDetailsData?.project_notes?.length > 5;
        setTotalNotes(totalNotesCount);
        setExpandList(isExpandable ? true : false);
    }, [projectDetailsData]);

    const expandNotes = () => {
        if (Array.isArray(projectDetailsData?.project_notes)) {
            setSliceNumber(projectDetailsData?.project_notes?.length);
            setExpandList(false);
        }
    };

    return (
        <>
            {projectDetailsData?.project_notes
                ?.slice(0, sliceNumber)
                ?.map((item, index) => {
                    return (
                        <CommentNote
                            createdDate={item?.created_at}
                            userPhoto={item?.author_details?.photo}
                            userName={item?.author_details?.name}
                            note={item?.note}
                            key={index}
                        />
                    );
                })}
            {expandList ? (
                <div
                    onClick={expandNotes}
                    className="notes-expand-button"
                >{`${totalNotes - 5}+ more comments `}</div>
            ) : null}
        </>
    );
};

export default NoteList;

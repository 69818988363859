import { useEffect, useState, useCallback } from "react";
import {
  Card,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  notification,
  Select,
  DatePicker,
} from "antd";

import { formatDate } from "utils/functions";
import { ContentHeader } from "components";
import "./MilestoneConfigComponent.scss";
import { DeleteTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  PROJECT_MILESTONE_GET,
  PROJECT_MILESTONE_CREATE,
  PROJECT_MILESTONE_DELETE,
  PROJECT_MILESTONE_REMOVEALL,
  MILESTONE_STATUS_LIST,
} from "utils/api";
import { postData, getData } from "utils/api-service";

const MilestoneConfigComponent = (props) => {
  // Props
  const { currentProjectId } = props;

  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [milestoneList, setMilestoneList] = useState(null);
  const [milestoneStatusList, setmilestoneStatusList] = useState(null);

  // Antd Constants
  const { Option } = Select;

  // Antd
  const [milestoneForm] = Form.useForm();
  const { confirm } = Modal;

  const getMilestoneStatusList = useCallback(async () => {
    let milestonequery = MILESTONE_STATUS_LIST();
    let milestoneesponse = await getData(milestonequery);
    if (milestoneesponse && milestoneesponse.code === 200) {
      setmilestoneStatusList(milestoneesponse.data.data);
    }
  }, []);

  useEffect(() => {
    getMilestoneStatusList();
  }, [getMilestoneStatusList]);

  const getMilestoneList = useCallback(async () => {
    const getMilestoneQuery = PROJECT_MILESTONE_GET();
    const queryWithId = `${getMilestoneQuery}?project_id=${currentProjectId}`;
    const response = await getData(queryWithId);
    if (response && response.code === 200) {
      setMilestoneList(response?.data?.data);
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (currentProjectId) {
      getMilestoneList();
    }
  }, [getMilestoneList, currentProjectId]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    milestoneForm.resetFields();
    setIsModalOpen(false);
  };

  const handleOk = () => {
    milestoneForm.submit();
    setIsModalOpen(false);
  };

  const deleteConfirm = (id, deleteFunction) => {
    confirm({
      title: "Are you sure you want to remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteFunction(id);
      },

      onCancel() {
        return null;
      },
    });
  };

  const deleteMilestone = async (milestoneId) => {
    const deleteQuery = PROJECT_MILESTONE_DELETE();
    const customQuery = `${deleteQuery}/${milestoneId}`;
    const response = await postData(customQuery);

    if (response && response.code === 200) {
      notification.success({
        message: "Success",
        description: `Milestone Deleted Successfully`,
        placement: "topLeft",
      });
      getMilestoneList();
    } else {
      notification.error({
        message: "Error",
        description: `Error Deleting Milestone`,
        placement: "topLeft",
      });
    }
  };

  const MilestoneCard = (props) => {
    // Props
    const {
      milestoneId,
      milestoneName,
      milestoneStatusName,
      milestoneStatusId,
    } = props;

    return (
      <Card size="small" className="milestoneCard" key={milestoneId}>
        <div className="milestoneListHeader">
          <div className="milestoneName">{milestoneName}</div>
          <div>{milestoneStatusName}</div>
          <div>
            <DeleteTwoTone
              onClick={() => deleteConfirm(milestoneId, deleteMilestone)}
            />
          </div>
        </div>
      </Card>
    );
  };

  const onFinish = async (values) => {
    // console.log("onfinish values: ", values);
    const formObject = {
      ...values,
      project_id: currentProjectId,
      start_date: formatDate(values?.start_date, true),
      end_date: formatDate(values?.end_date, true),
      status_id: values?.milestone_status_id,
    };

    if (!formObject.project_id) {
      notification.error({
        message: `No Project Found! Please Create Project First`,
        placement: "topLeft",
      });
      return null;
    }

    const milestoneCreateQuery = PROJECT_MILESTONE_CREATE();
    const response = await postData(milestoneCreateQuery, formObject);
    if (response && response.code === 201) {
      notification.success({
        message: "Success",
        description: `Milestone Created Successfully`,
        placement: "topLeft",
      });
      getMilestoneList();
      milestoneForm.resetFields();
    } else {
      notification.error({
        message: `Milestone Create Failed`,
        placement: "topLeft",
      });
    }
  };

  const removeAllMilestone = async (currentProjectId) => {
    const removeAllQuery = PROJECT_MILESTONE_REMOVEALL();
    const customQuery = `${removeAllQuery}/${currentProjectId}`;
    const response = await postData(customQuery);

    if (response && response.code === 200) {
      notification.success({
        message: "Success",
        description: `All Milestones Removed Successfully`,
        placement: "topLeft",
      });
      getMilestoneList();
    } else {
      notification.error({
        message: "Error",
        description: `Error Removing All Milestone`,
        placement: "topLeft",
      });
    }
  };

  return (
    <>
      <Card className="milestoneContainer">
        <ContentHeader
          contentTitle={"Milestone List"}
          primaryButtonTitle={"Create New Milestone"}
          primaryButtonClickFunction={showModal}
          titleFontSize="14px"
          primaryButtonSize="small"
        />
        <div className="milestoneListHeader">
          <div>Milestone List</div>
          <div>
            <Button
              type="link"
              onClick={() =>
                deleteConfirm(currentProjectId, removeAllMilestone)
              }
              disabled={
                milestoneList && milestoneList.length > 0 ? false : true
              }
            >
              Delete All
            </Button>
          </div>
        </div>
        <div className="milestoneListContainer">
          {milestoneList &&
            milestoneList.map((item, index) => {
              return (
                <MilestoneCard
                  milestoneId={item?.id}
                  milestoneName={item?.name}
                  key={item?.id}
                  milestoneStatusId={item?.milestone_status?.id}
                  milestoneStatusName={item?.milestone_status?.name}
                />
              );
            })}
        </div>
      </Card>
      <Modal
        title="Milestone Form"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          className="milestoneForm"
          layout="vertical"
          onFinish={onFinish}
          form={milestoneForm}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Milestone Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter Milestone Name",
                  },
                ]}
              >
                <Input placeholder="Please enter Milestone Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="milestone_status_id"
                label="Milestone Status"
                rules={[
                  {
                    required: true,
                    message: "Please select milestone status",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Milestone Status"
                  optionFilterProp="children"
                  // onChange={onChange}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {milestoneStatusList?.map((e) => (
                    <Option value={e.id} name={e.name} key={e.id}>
                      {e.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="start_date" label="Start Date">
                <DatePicker showTime />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="end_date" label="End Date">
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default MilestoneConfigComponent;

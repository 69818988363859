import { createContext } from "react";

export const ThemeContext = createContext({});

const ThemeContextProvider = ({ children }) => {
    const themeSettings = {
        colorPrimary: "#517bc3",
        colorSuccess: "#56b726",
        colorWarning: "#eace17",
        colorError: "#e64b17",
        colorInfo: "#1bb1b1",
        wireframe: false,
    };

    return (
        <ThemeContext.Provider value={{ themeSettings }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;

import React, { useState, useContext, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { AuthContext, RouteContext } from "context";
import { UserHeaderCard } from "components";
import { useLocation } from "react-router-dom";
import "./LayoutComponent.scss";

// Antd Constants
const { Header, Sider, Content } = Layout;

const LayoutComponent = ({ children }) => {
  // States
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenuKeys, setSelectedMenuKeys] = useState(["1"]);

  // Router
  const location = useLocation();

  // Contexts
  const { menuItems, auth } = useContext(AuthContext);
  const { goToRoute } = useContext(RouteContext);

  const clickOnMenu = (e) => {
    // Change routes based on `to` property of menu item
    if (e?.item?.props?.to) {
      setSelectedMenuKeys([e?.key]);
      goToRoute(e.item.props.to);
    } else {
      setSelectedMenuKeys(["1"]);
      goToRoute("/");
    }
  };

  useEffect(() => {
    // Select default menu accord
    if (location && menuItems) {
      const currentPath = location.pathname;
      const foundMenuItem = menuItems?.find((item) => item?.to === currentPath);
      foundMenuItem && setSelectedMenuKeys([foundMenuItem?.key]);
    }
  }, [location, menuItems]);

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logoContainer">
          <div className="logo" onClick={() => goToRoute("/")}>
            <img src="/assets/icons/ssl_logo.png" alt="SSL" />
          </div>
        </div>
        {selectedMenuKeys && (
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={selectedMenuKeys}
            items={menuItems}
            onClick={clickOnMenu}
          />
        )}
      </Sider>
      <Layout className="site-layout">
        <Header
          className="userHeader"
          style={{
            padding: 0,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <UserHeaderCard
            username={auth.user.name || "Username"}
            positionName={auth.user.type || "Position Name"}
            imagePosition="right"
            imageLink="/assets/icons/user_image_1.png"
          />
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;

import { Button, Menu } from "antd";
import {
    DownloadOutlined,
    SmileOutlined,
    LeftOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from 'components';

const menu = (
    <Menu
        items={[
            {
                key: "1",
                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.antgroup.com"
                    >
                        1st menu item
                    </a>
                ),
            },
            {
                key: "2",
                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.aliyun.com"
                    >
                        2nd menu item (disabled)
                    </a>
                ),
                icon: <SmileOutlined />,
                disabled: true,
            },
        ]}
    />
);

const OverviewTopBar = () => {
    // Router
    const navigate = useNavigate();

    const downloadReport = () => {
        // console.log('downloadReport');
    }

    return (
        <div className="overview-topbar">
            <div>
                <Button
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </div>
            {/* <div className="right-container">
                <Button type="primary" icon={<DownloadOutlined />} onClick={downloadReport}>
                    Download Report
                </Button>
                <div className="dots-menu-container">
                    <ThreeDots menu={menu}/>
                </div>
            </div> */}
        </div>
    );
};

export default OverviewTopBar;

import {
    Col,
} from "antd";
import moment from "moment";
import { BASE_URL } from "utils/constants";


const CommentNote = (props) => {
    // Props
    const {createdDate, userPhoto, userName, note} = props;


    return (
        <Col span={24}>
            <div className="note-container">
                <div className="overview-title-small">
                    {moment(createdDate).format("MMM DD, YYYY | HH:ss")}
                </div>
                <div className="comment-container">
                    <div className="currentUserImage-container">
                        <img
                            className="currentUserImage"
                            src={
                                userPhoto
                                    ? `${BASE_URL}/${userPhoto}`
                                    : "/assets/icons/user_image_1.png"
                            }
                            alt="Resource"
                        />
                    </div>
                    <div className="userInfo">
                        <div className="currentUserName">{userName}</div>
                        <div>
                            {note}
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default CommentNote;
import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Space,
  Card,
  Typography,
  Button,
  Drawer,
  Form,
  notification,
  Popconfirm,
  Table,
} from "antd";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { ContentHeader, SupportStatusForm } from "components";
import "./SupportStatusComponent.scss";
import {
  SUPPORT_STATUS_CREATE,
  SUPPORT_STATUS_LIST,
  SUPPORT_STATUS_DELETE,
  SUPPORT_STATUS_UPDATE,
} from "../../utils/api";
import { postData, getData } from "../../utils/api-service";

// Antd Constants
const { Title } = Typography;

const SupportStatusComponent = (props) => {
  const { setParentCurrentPage } = props;
  // States
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [flag, setFlag] = useState("");
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [supportStatus, setSupportStatus] = useState();
  const [refresh, setRefresh] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  // Antd
  const [supportStatusForm] = Form.useForm();

  const refetch = () => {
    setRefresh(refresh + 1);
  };
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();

  const showDrawer = () => {
    setDrawerVisible(true);
    setFlag("Create");
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onFinish = (values) => {
    setLoader(true);
    submitSupportStatusForm(values);
  };

  const submitSupportStatusForm = async (values) => {
    if (flag === "Create") {
      let requestBody = {};
      requestBody.name = values.name;
      requestBody.is_active = values.is_active ? values.is_active : false;
      let query = SUPPORT_STATUS_CREATE();
      let response = await postData(query, requestBody);
      if (response && response.code === 201) {
        closeDrawer();
        notification.success({
          message: "Success",
          description: `${"Saved successfully"}`,
          placement: 'topLeft',
        });

        setLoader(false);
        refetch();
      } else {
        notification.error({
          message: "Error",
          placement: 'topLeft',
        });
      }
    }

    if (flag === "Edit") {
      let requestBody = {};
      requestBody.name = values.name;
      requestBody.is_active = values.is_active ? values.is_active : false;
      let query = SUPPORT_STATUS_UPDATE() + "/" + parseInt(rowData?.id);
      let response = await postData(query, requestBody);
      if (response && response.code === 201) {
        closeDrawer();
        notification.success({
          message: "Success",
          description: `${"Updated successfully"}`,
          placement: 'topLeft',
        });

        setLoader(false);
        refetch();
      } else {
        notification.error({
          message: "Error",
          placement: 'topLeft',
        });
      }
    }
  };

  const resetFlagAndRowData = () => {
    setFlag("");
    setRowData(null);
  };

  const cancel = (e) => {};

  const handleDelete = async (e) => {
    let query = SUPPORT_STATUS_DELETE() + "/" + e.variables.deleteTypeId;
    let response = await postData(query);
    if (response && response.code === 200) {
      closeDrawer();
      notification.success({
        message: "Success",
        description: `${"Support Status Deleted Successfully"}`,
        placement: 'topLeft',
      });

      setLoader(false);
    } else {
      notification.error({
        message: "Error",
        placement: 'topLeft',
      });
    }

    refetch();
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let page = currentPage;
      let query = SUPPORT_STATUS_LIST();
      let response = await getData(query);

      if (response && response.code === 200) {
        setSupportStatus(response.data.data);
        setPageCount("pageCount", response.data.data.total);
        setLoader(false);
      } else {
        notification.error({
          message: "Error getting data",
          placement: 'topLeft',
        });

        setLoader(false);
      }
    };

    // call the function
    fetchData();
    // make sure to catch any error
  }, [refresh]);

  const setCurrentPageToStates = (page) => {
    setCurrentPage(page);

    if (setParentCurrentPage) {
      setParentCurrentPage(page);
    }
  };

  const paginate = (page) => {
    setCurrentPageToStates(page);
  };

  const sizeChange = (currentPage, size) => {
    setCurrentPageToStates(currentPage);
    setPerPageCount(size);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Status",
      key: "is_active",
      dataIndex: "is_active",
      render: (row) => (row === 1 ? "Active" : "Inactive"),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (row) => (
        <Space size="middle">
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              setDrawerVisible(true);
              setFlag("Edit");
              setRowData(row);
            }}
          >
            <FormOutlined className="icon-primary-color" />
          </Button>
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={(e) => {
              setLoader(true);
              handleDelete({
                variables: {
                  deleteTypeId: parseInt(row?.id),
                },
              });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="link" style={{ padding: 0 }}>
              <DeleteOutlined className="icon-secondary-color" />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <ContentHeader
            contentTitle="Charge Type"
            primaryButtonTitle="Create"
            primaryButtonClickFunction={showDrawer}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="card mb-4">
            <Card>
              <Table
                scroll={{ x: true }}
                size="middle"
                pagination={{
                  current: currentPage,
                  total: pageCount * perPageCount,
                  onShowSizeChange: sizeChange,
                  onChange: (page) => paginate(page),
                }}
                columns={columns}
                refresh={refresh}
                dataSource={supportStatus || []}
              />
            </Card>
          </div>
        </Col>
      </Row>
      <Drawer
        title={
          flag === "Create" ? "Create Support Status" : "Update Support Status"
        }
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        width={600}
        extra={
          <Space>
            <Button onClick={closeDrawer} danger>
              Cancel
            </Button>
            <Button onClick={() => supportStatusForm.submit()} type="primary">
              Submit
            </Button>
          </Space>
        }
      >
        <SupportStatusForm
          toggleDrawer={toggleDrawer}
          onFinish={onFinish}
          refetch={refetch}
          rowData={rowData}
          formName={supportStatusForm}
          visible={drawerVisible}
          flag={flag}
          resetFlagAndRowData={resetFlagAndRowData}
          refresh={refresh}
          setLoader={setLoader}
          setFlag={setFlag}
        />
      </Drawer>
    </>
  );
};

export default SupportStatusComponent;

import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Select,
    DatePicker,
    notification,
    Card,
    Steps,
    Affix,
} from "antd";
import "./ProjectDetailsComponent.scss";
import {
    ContentHeader,
    ProjectInfoComponent,
    MilestoneConfigComponent,
    ChecklistComponent,
    ResourceAddComponent,
} from "components";
import {
    PROJECT_TYPE_LIST,
    BUSINESS_UNIT_LIST,
    MILESTONE_STATUS_LIST,
    CHARGE_TYPE_LIST,
    SUPPORT_STATUS_LIST,
    PROJECT_DETAILS_GET,
    PROJECT_DETAILS_UPDATE,
    PROJECT_CREATE,
} from "utils/api";
import { postData, getData } from "utils/api-service";
import { 
    formatDate, 
    renderContentTitle,
    renderPrimaryButtonTitle,
    renderSecondaryButtonTitle,
} from "utils/functions";
import { useParams, useNavigate } from "react-router-dom";

//   Antd Constants
const { Step } = Steps;

const ProjectDetailsComponent = (props) => {
    // States
    const [projectTypes, setProjectTypes] = useState();
    const [businessUnits, setBusinessUnits] = useState();
    const [milestoneStatus, setMilestoneStatus] = useState();
    const [chargeTypes, setChargeTypes] = useState();
    const [supportStatus, setSupportStatus] = useState();
    const [projectDetailsData, setProjectDetailsData] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [createdProjectId, setCreatedProjectId] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);


    // Router
    const { projectId } = useParams();
    const navigate = useNavigate();

    // Antd
    const [projectForm] = Form.useForm();

    useEffect(() => {
        if (projectForm) {
            projectForm.setFieldsValue({
                ...projectDetailsData,
                project_status: projectDetailsData?.project_status?.id,
                wo_start_date: formatDate(projectDetailsData?.wo_start_date),
                wo_end_date: formatDate(projectDetailsData?.wo_end_date),
                start_date: formatDate(projectDetailsData?.start_date),
                expected_end_date: formatDate(
                    projectDetailsData?.expected_end_date
                ),
                recurring_period_start_date: formatDate(
                    projectDetailsData?.recurring_period_start_date
                ),
                recurring_period_end_date: formatDate(
                    projectDetailsData?.recurring_period_end_date
                ),
            });
        }
    }, [projectDetailsData, projectForm]);

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async () => {
            let query = PROJECT_TYPE_LIST();
            let response = await getData(query);
            if (response && response.code === 200) {
                setProjectTypes(response.data.data);
            }

            let unitsquery = BUSINESS_UNIT_LIST();
            let unitresponse = await getData(unitsquery);
            if (unitresponse && unitresponse.code === 200) {
                setBusinessUnits(unitresponse.data.data);
            }

            let milestonequery = MILESTONE_STATUS_LIST();
            let milestoneesponse = await getData(milestonequery);
            if (milestoneesponse && milestoneesponse.code === 200) {
                setMilestoneStatus(milestoneesponse.data.data);
            }

            let chargeTypesquery = CHARGE_TYPE_LIST();
            let chargeTypesRes = await getData(chargeTypesquery);
            if (chargeTypesRes && chargeTypesRes.code === 200) {
                setChargeTypes(chargeTypesRes.data.data);
            }

            let supportStatusQuery = SUPPORT_STATUS_LIST();
            let supportStatusRes = await getData(supportStatusQuery);
            if (supportStatusRes && supportStatusRes.code === 200) {
                setSupportStatus(supportStatusRes.data.data);
            }

            const projectDetailsQuery = PROJECT_DETAILS_GET();
            const queryWithId = `${projectDetailsQuery}/${projectId}`;
            let projectDetailsRes = projectId
                ? await getData(queryWithId)
                : null;
            if (projectDetailsRes && projectDetailsRes.code === 201) {
                setProjectDetailsData(projectDetailsRes.data);
            }
        };

        fetchData();
    }, [projectId]);

    const pushDataToAPI = async (query, data, isEdit, editItemId) => {
        const projectID = editItemId ? editItemId : createdProjectId ? createdProjectId : '';
        
        const customQuery = (isEdit || createdProjectId) ? `${query}/${projectID}` : `${query}`;
        let response = await postData(customQuery, data);
        if (response && response.code === 201) {
            notification.success({
                message: "Success",
                description: `Project ${
                    (isEdit || createdProjectId) ? "Updated" : "Created"
                } Successfully`,
                placement: "topLeft",
            });
            setCreatedProjectId(response?.data?.id);
            goNextStep();
        } else {
            notification.error({
                message: `Project ${(isEdit || createdProjectId) ? "Update" : "Create"} Failed`,
                placement: "topLeft",
            });
        }
    };

    const onFinish = async (values) => {
        // console.log("onfinish values: ", values);

        const formDataObject = {
            ...values,
            wo_start_date: formatDate(values?.wo_start_date, true),
            wo_end_date: formatDate(values?.wo_end_date, true),
            start_date: formatDate(values?.start_date, true),
            expected_end_date: formatDate(values?.expected_end_date, true),
            recurring_period_start_date: formatDate(
                values?.recurring_period_start_date,
                true
            ),
            recurring_period_end_date: formatDate(
                values?.recurring_period_end_date,
                true
            ),
        };

        const projectDetailsUpdateQuery = PROJECT_DETAILS_UPDATE();
        const projectCreateQuery = PROJECT_CREATE();

        pushDataToAPI(
            (projectDetailsData || createdProjectId) ? projectDetailsUpdateQuery : projectCreateQuery,
            formDataObject,
            projectDetailsData,
            projectId
        );
    };

    const onChangeStep = (value) => {
        setCurrentStep(value);
    };

    const goNextStep = () => {
        if(currentStep < 3) {
            setCurrentStep(currentStep+1);
        }
    }

    const goPrevStep = () => {
        if(currentStep > 0) {
            setCurrentStep(currentStep-1);
        }
    }

    const ComponentRenderer = ({ currentPage }) => {
        switch (currentPage) {
            case 0:
                return (
                    <ProjectInfoComponent
                        onFinish={onFinish}
                        formName={projectForm}
                        projectTypes={projectTypes}
                        businessUnits={businessUnits}
                        chargeTypes={chargeTypes}
                        supportStatus={supportStatus}
                        milestoneStatus={milestoneStatus}
                    />
                );
            case 1:
                return <MilestoneConfigComponent currentProjectId={createdProjectId || projectId} />;
            case 2:
                return <ChecklistComponent currentProjectId={createdProjectId || projectId} projectDetailsData={projectDetailsData}/>;
            case 3:
                return <ResourceAddComponent currentProjectId={createdProjectId || projectId} />;
            default:
                return null;
        }
    };

    const primaryButtonClickFunction = (stepId) => {
        switch(stepId) {
            case 0:
                projectForm.submit();
                return null;
            case 1:
                goNextStep();
                return null;
            case 2:
                goNextStep();
                return null;
            case 3:
                navigate(`/projects`);
                return null;
            default:
                return null;
        }
    }

    const secondaryButtonClickFunction = (stepId) => {
        switch(stepId) {
            case 0:
                return null;
            case 1:
                goPrevStep();
                return null;
            case 2:
                goPrevStep();
                return null;
            case 3:
                goPrevStep();
                return null;
            default:
                return null;
        }
    }


    return (
        <>
            <Row>
                <Col span={24}>
                    <Affix>
                        <ContentHeader
                            contentTitle={renderContentTitle(currentStep, projectDetailsData)}
                            primaryButtonTitle={renderPrimaryButtonTitle(currentStep, projectDetailsData, createdProjectId)}
                            primaryButtonClickFunction={() => primaryButtonClickFunction(currentStep)}
                            secondaryButtonTitle={renderSecondaryButtonTitle(currentStep)}
                            secondaryButtonClickFunction={() => secondaryButtonClickFunction(currentStep)}
                        />
                    </Affix>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Card
                        bordered={false}
                        style={{
                            width: "95%",
                        }}
                    >
                        <Steps
                            current={currentStep}
                            onChange={onChangeStep}
                            direction="vertical"
                            size="small"
                        >
                            <Step
                                title="Project Information"
                            />
                            <Step
                                title="Milestone Configuration"
                            />
                            <Step
                                title="Checklist"
                            />
                            <Step
                                title="Resources/Members"
                            />
                        </Steps>
                    </Card>
                </Col>
                <Col span={16}>
                    <ComponentRenderer currentPage={currentStep}/>
                </Col>
            </Row>
        </>
    );
};

export default ProjectDetailsComponent;

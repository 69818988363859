import {
  Row,
  Col,
  Form,
  Input,
  Switch,
  message,
  Upload,
  Select,
  DatePicker,
  Space,
} from "antd";
import "./ProjectForm.scss";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  PROJECT_TYPE_LIST,
  BUSINESS_UNIT_LIST,
  MILESTONE_STATUS_LIST,
  CHARGE_TYPE_LIST,
  SUPPORT_STATUS_LIST,
} from "../../../utils/api";
import { postData, getData } from "../../../utils/api-service";
import { BASE_URL, FILE_UPLOAD_PATH } from "../../../utils/constants";
const { Option } = Select;
const { RangePicker } = DatePicker;
const ProjectForm = (props) => {
  // Props
  const { onFinish, formName } = props;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [projectTypes, setProjectTypes] = useState();
  const [businessUnits, setBusinessUnits] = useState();
  const [milestoneStatus, setMilestoneStatus] = useState();
  const [chargeTypes, setChargeTypes] = useState();
  const [supportStatus, setSupportStatus] = useState();
  const [refresh, setRefresh] = useState(0);
  const [filterQueryData, setFilterQueryData] = useState({});

  const onChange = (value) => {};

  const onSearch = (value) => {};

  const woStartaDteRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      wo_start_date: dateString,
    });
  };
  const woEndDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      wo_end_date: dateString,
    });
  };
  const exEndDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      expected_end_date: dateString,
    });
  };
  const satrtDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      start_date: dateString,
    });
  };

  const recPerDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      recurring_period_start_date: dateString,
    });
  };
  const recPerEndDateRangeChange = (value, dateString) => {
    formName.setFieldsValue({
      recurring_period_end_date: dateString,
    });
  };
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      let query = PROJECT_TYPE_LIST();
      let response = await getData(query);
      if (response && response.code === 200) {
        setProjectTypes(response.data.data);
      }

      let unitsquery = BUSINESS_UNIT_LIST();
      let unitresponse = await getData(unitsquery);
      if (unitresponse && unitresponse.code === 200) {
        setBusinessUnits(unitresponse.data.data);
      }

      let milestonequery = MILESTONE_STATUS_LIST();
      let milestoneesponse = await getData(milestonequery);
      if (milestoneesponse && milestoneesponse.code === 200) {
        setMilestoneStatus(milestoneesponse.data.data);
      }

      let chargeTypesquery = CHARGE_TYPE_LIST();
      let chargeTypesRes = await getData(chargeTypesquery);
      if (chargeTypesRes && chargeTypesRes.code === 200) {
        setChargeTypes(chargeTypesRes.data.data);
      }

      let supportStatusQuery = SUPPORT_STATUS_LIST();
      let supportStatusRes = await getData(supportStatusQuery);
      if (supportStatusRes && supportStatusRes.code === 200) {
        setSupportStatus(supportStatusRes.data.data);
      }
    };

    fetchData();
  }, []);

  return (
    <Form layout="vertical" onFinish={onFinish} form={formName}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="jira_key"
            label="Jira Key"
            rules={[
              {
                required: true,
                message: "Please enter Jira Key",
              },
            ]}
          >
            <Input placeholder="Please enter Jira Key" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="project_code"
            label="Project Code"
            rules={[
              {
                required: true,
                message: "Please enter Project Code",
              },
            ]}
          >
            <Input placeholder="Please enter Project Code" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Project Name"
            rules={[
              {
                required: true,
                message: "Please enter Project Name",
              },
            ]}
          >
            <Input placeholder="Please enter Project Name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="project_type_id" label="Project Type">
            <Select
              showSearch
              placeholder="Select project type"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {projectTypes?.map((e) => (
                <Option value={e.id} name={e.type_name} key={e.id}>
                  {e.type_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="business_unit_id" label="Business Unit">
            <Select
              showSearch
              placeholder="Select project type"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {businessUnits?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="project_status"
            label="Project Status"
            rules={[
              {
                required: true,
                message: "Please select project status",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select status"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {milestoneStatus?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="wo_ref"
            label="WO Ref"
            rules={[
              {
                required: true,
                message: "Please enter WO Ref",
              },
            ]}
          >
            <Input placeholder="Please enter WO Ref" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="wo_start_date" label="WO Start Date">
            <Space direction="vertical" size={12}>
              <DatePicker showTime onChange={woStartaDteRangeChange} />
            </Space>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="wo_end_date" label="WO End Date">
            <Space direction="vertical" size={12}>
              <DatePicker showTime onChange={woEndDateRangeChange} />
            </Space>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="start_date" label="Start Date">
            <Space direction="vertical" size={12}>
              <DatePicker showTime onChange={satrtDateRangeChange} />
            </Space>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="expected_end_date" label="Expected End Date">
            <Space direction="vertical" size={12}>
              <DatePicker showTime onChange={exEndDateRangeChange} />
            </Space>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="expected_hour"
            label="Expected Hour"
            rules={[
              {
                required: true,
                message: "Please enter Expected Hour",
              },
            ]}
          >
            <Input placeholder="Please enter Expected Hour" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="spent_hour" label="Spent Hour">
            <Input placeholder="Please enter Spent Hour" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="charge_type_id"
            label="Charge Type"
            rules={[
              {
                required: true,
                message: "Please select project status",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select status"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {chargeTypes?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="support_status_id"
            label="Support Status"
            rules={[
              {
                required: true,
                message: "Please select support status",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select status"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {supportStatus?.map((e) => (
                <Option value={e.id} name={e.name} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="otc"
            label="OTC"
            rules={[
              {
                required: true,
                message: "Please enter OTC",
              },
            ]}
          >
            <Input placeholder="Please enter OTC" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="mrc"
            label="MRC"
            rules={[
              {
                required: true,
                message: "Please enter MRC",
              },
            ]}
          >
            <Input placeholder="Please enter MRC" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="amc"
            label="AMC"
            rules={[
              {
                required: true,
                message: "Please enter AMC",
              },
            ]}
          >
            <Input placeholder="Please enter AMC" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="recurring_period_start_date"
            label="Recurring Period Start Date"
          >
            <Space direction="vertical" size={12}>
              <DatePicker showTime onChange={recPerDateRangeChange} />
            </Space>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="recurring_period_end_date"
            label="Recurring Period End Date"
          >
            <Space direction="vertical" size={12}>
              <DatePicker showTime onChange={recPerEndDateRangeChange} />
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ProjectForm;

import { useState, useContext } from "react";
import {
    Row,
    Col,
    Input,
    notification,
    Card,
    Button,
} from "antd";
import { BASE_URL } from "utils/constants";
import {
    PROJECT_NOTE_CREATE,
} from "utils/api";
import { postData, getData } from "utils/api-service";
import { NoteList } from 'components';
import { AuthContext } from "context";

const dummyCurrentUser =  {
    "resource_details": {
        "id": 1,
        "employee_id": 101,
        "email": "resource01@test.com",
        "name": "Resource 1",
        "phone": "01743906668",
        "photo": "storage/images/resource/image/1664094524_logo.png",
        "cost_per_hour": "60",
        "team_id": 1,
        "resource_type_id": 1,
        "is_active": 1,
        "created_at": "2022-09-11T06:23:19.000000Z",
        "updated_at": "2022-09-14T05:13:30.000000Z",
        "team_details": {
            "id": 1,
            "name": "Team 1",
            "team_lead": 1,
            "created_at": "2022-09-11T06:20:03.000000Z",
            "updated_at": "2022-09-11T06:23:40.000000Z"
        },
        "resource_type_details": {
            "id": 1,
            "name": "PMO",
            "is_active": 1,
            "created_at": "2022-09-11T07:11:02.000000Z",
            "updated_at": "2022-09-14T05:13:46.000000Z"
        }
    }
};

const ProjectNotesComponent = (props) => {
    // Props
    const { fetchProjectData, projectDetailsData } = props;

    // States
    const [notesInput, setNotesInput] = useState(null);

    // Contexts
    const { auth } = useContext(AuthContext);


    const onChangeNote = (event) => {
        setNotesInput(event.target.value);
    }

    const submitProjectNote = async (noteText, projectID, userEmployeeID) => {
        const formObject = {
            project_id: projectID,
            author_id: userEmployeeID,
            note: noteText,
        };

        if (!formObject.project_id) {
            notification.error({
                message: `No Project Found! Please Create Project First`,
                placement: "topLeft",
            });
            return null;
        }

        if (!formObject.note) {
            notification.error({
                message: `No note text found. Please type something.`,
                placement: "topLeft",
            });
            return null;
        }

        const noteCreateQuery = PROJECT_NOTE_CREATE();
        const response = await postData(noteCreateQuery, formObject);
        if (response && response.code === 201) {
            notification.success({
                message: "Success",
                description: `Note Created Successfully`,
                placement: "topLeft",
            });
            setNotesInput(null);
            fetchProjectData();
        } else {
            notification.error({
                message: `Note Create Failed`,
                placement: "topLeft",
            });
        }
    };

    return (
        <Card>
            <Row justify="center" style={{ paddingBottom: 10 }}>
                <Col>
                    <div>Project Notes</div>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                    <div className="currentUserImage-container">
                        <img
                            className="currentUserImage"
                            src={
                                dummyCurrentUser?.resource_details?.photo
                                    ? `${BASE_URL}/${dummyCurrentUser.resource_details.photo}`
                                    : "/assets/icons/user_image_1.png"
                            }
                            alt="Resource"
                        />
                    </div>
                </Col>
                <Col xs={12} sm={16} md={16} lg={16} xl={16}>
                    <Input
                        value={notesInput}
                        placeholder="Add a note"
                        onChange={onChangeNote}
                    />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                    <div style={{ width: "100%" }}>
                        <Button
                            type="primary"
                            onClick={() =>
                                submitProjectNote(
                                    notesInput,
                                    projectDetailsData?.id,
                                    auth?.user?.id,
                                )
                            }
                            style={{ width: "100%" }}
                        >
                            Add Comment
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <NoteList 
                    projectDetailsData={projectDetailsData}
                />
            </Row>
        </Card>
    );
};

export default ProjectNotesComponent;
import { useState, useCallback, useEffect } from "react";
import {
  Card,
  Input,
  notification,
  Row,
  Col,
  Space,
  Button,
  Modal,
  Form,
  DatePicker,
} from "antd";
import { ContentHeader, LoadingComponent } from "components";
import { postData, getData } from "utils/api-service";
import {
  PROJECT_RESOURCES_GET,
  PROJECT_RESOURCES_CREATE,
  PROJECT_RESOURCES_DELETE,
  RESOURCE_LIST,
  PROJECT_RESOURCES_REMOVEALL,
  PROJECT_RESOURCES_UPDATE,
} from "utils/api";
import { BASE_URL } from "utils/constants";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import "./ResourceAddComponent.scss";
import { formatDate } from "utils/functions";
import moment from "moment";

const ResourceAddComponent = (props) => {
  // Props
  const { currentProjectId } = props;

  // States
  const [projectResources, setProjectResources] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Antd Constants
  const { Search } = Input;
  const { confirm } = Modal;
  const [resourceAddForm] = Form.useForm();

  const getProjectResources = useCallback(async () => {
    setIsLoading(true);

    const getResourcesQuery = PROJECT_RESOURCES_GET();
    const queryWithId = `${getResourcesQuery}?projects_id=${currentProjectId}`;
    const response = await getData(queryWithId);
    if (response && response.code === 200) {
      setProjectResources(response?.data?.data);
      setIsLoading(false);
    } else {
      notification.error({
        message: "Error",
        description: `Error Getting Project Resources`,
        placement: "topLeft",
      });
      setIsLoading(false);
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (currentProjectId) {
      getProjectResources();
    }
  }, [getProjectResources, currentProjectId]);

  const searchResources = async (searchInput) => {
    setIsLoading(true);

    const query = RESOURCE_LIST();
    const customQuery = `${query}?search=${searchInput}`;
    let response = await getData(customQuery);

    if (response && response.code === 200) {
      setSearchResult(response?.data?.data);
      setIsLoading(false);
    } else {
      notification.error({
        message: response.message
          ? "Error getting data"
          : `${response.message}`,
        placement: "topLeft",
      });
      setIsLoading(false);
    }
  };

  const onSearch = useCallback((value) => {
    if (value) {
      searchResources(value);
    } else {
      setSearchResult(null);
    }
  }, []);

  useEffect(() => {
    onSearch(searchInput);
  }, [searchInput, onSearch]);

  const resetSearch = () => {
    setSearchInput(null);
    setSearchResult(null);
  };

  const addResourceToProject = async (resourceID, engaged_from, engaged_to) => {
    if (resourceID) {
      const formObject = {
        projects_id: currentProjectId,
        resources_id: resourceID,
        engaged_from: formatDate(engaged_from, true),
        engaged_to: formatDate(engaged_to, true),
      };

      if (!formObject.projects_id) {
        notification.error({
          message: `No Project Found! Please Create Project First`,
          placement: "topLeft",
        });
        return null;
      }

      const resourceAddQuery = PROJECT_RESOURCES_CREATE();
      const response = await postData(resourceAddQuery, formObject);

      if (response && response.code === 201) {
        notification.success({
          message: "Success",
          description: `Resource Added Successfully`,
          placement: "topLeft",
        });
        handleCancel();
        getProjectResources();
        setSearchResult(null);
      } else {
        handleCancel();
        notification.error({
          message: response
            ? `${response.messages[0]}`
            : "Error Adding Resource",
          placement: "topLeft",
        });
      }
    }
  };

  const updateProjectResource = async (
    resourceID,
    engaged_from,
    engaged_to
  ) => {
    if (resourceID) {
      const formObject = {
        projects_id: currentProjectId,
        resources_id: resourceID,
        engaged_from: formatDate(engaged_from, true),
        engaged_to: formatDate(engaged_to, true),
      };

      if (!formObject.projects_id) {
        notification.error({
          message: `No Project Found! Please Create Project First`,
          placement: "topLeft",
        });
        return null;
      }

      const resourceUpdateQuery = PROJECT_RESOURCES_UPDATE();
      const response = await postData(resourceUpdateQuery, formObject);

      if (response && response.code === 201) {
        notification.success({
          message: "Success",
          description: `Resource Updated Successfully`,
          placement: "topLeft",
        });
        handleCancel();
        getProjectResources();
      } else {
        handleCancel();
        notification.error({
          message: response
            ? `${response.messages[0]}`
            : "Error Updating Resource",
          placement: "topLeft",
        });
      }
    }
  };

  const deleteConfirm = (id, deleteFunction) => {
    confirm({
      title: "Are you sure you want to remove?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteFunction(id);
      },

      onCancel() {
        return null;
      },
    });
  };

  const removeResourceFromProject = async (resourceID) => {
    const deleteQuery = PROJECT_RESOURCES_DELETE();
    const customQuery = `${deleteQuery}`;
    const formObject = {
      projects_id: currentProjectId,
      resources_id: resourceID,
    };
    const response = await postData(customQuery, formObject);

    if (response && response.code === 201) {
      notification.success({
        message: "Success",
        description: `Resource Removed Successfully`,
        placement: "topLeft",
      });
      getProjectResources();
    } else {
      notification.error({
        message: "Error",
        description: `Error Removing Resource`,
        placement: "topLeft",
      });
    }
  };

  const ResourceCard = ({ item, existed }) => {
    return (
      <Row>
        <Col span={24} className="resourceCardColumn">
          <div className="resourceContainer">
            <div className="resourceInfoContainer">
              <Space>
                <img
                  className="resourceImage"
                  src={
                    item?.resource_details?.photo
                      ? `${BASE_URL}/${item.resource_details?.photo}`
                      : "/assets/icons/user_image_1.png"
                  }
                  alt="Resource"
                />
                <div className="resourceDetailsContainer">
                  <div className="resourceName">
                    {item?.resource_details?.name || ""}
                  </div>
                  <div className="resourcePositionName">
                    {item?.resource_details?.resource_type_details?.name || ""}
                  </div>
                  {item?.engaged_from || item?.engaged_to ? (
                    <div className="resourcePositionName">
                      {`Engaged from ${moment(item?.engaged_from).format(
                        "Do MMM YYYY"
                      )} to ${moment(item?.engaged_to).format("Do MMM YYYY")}`}
                    </div>
                  ) : null}
                </div>
              </Space>
            </div>
            <div>
              {existed ? (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      showModal(
                        currentProjectId,
                        item?.resource_details?.id,
                        "edit"
                      );
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="danger"
                    onClick={() =>
                      deleteConfirm(
                        item?.resource_details?.id,
                        removeResourceFromProject
                      )
                    }
                  >
                    Remove
                  </Button>
                </Space>
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    showModal(
                      currentProjectId,
                      item?.resource_details?.id,
                      "add"
                    );
                  }}
                >
                  Add
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const removeAllResources = async (currentProjectId) => {
    const removeAllQuery = PROJECT_RESOURCES_REMOVEALL();
    const customQuery = `${removeAllQuery}/${currentProjectId}`;
    const response = await postData(customQuery);

    if (response && response.code === 200) {
      notification.success({
        message: "Success",
        description: `All Resources Removed Successfully`,
        placement: "topLeft",
      });
      getProjectResources();
    } else {
      notification.error({
        message: "Error",
        description: `Error Removing All Resources`,
        placement: "topLeft",
      });
    }
  };

  const showModal = (projectId, resourceId, actionType) => {
    setIsModalOpen(true);
    setSelectedAction(actionType);
    resourceAddForm.setFieldValue("projects_id", projectId);
    resourceAddForm.setFieldValue("resources_id", resourceId);
  };

  const handleOk = () => {
    resourceAddForm.submit();
    setSelectedAction(null);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    resourceAddForm.resetFields();
    setSelectedAction(null);
    setIsModalOpen(false);
  };

  const onFinishResourceAdd = (data) => {
    if (selectedAction === "add") {
      addResourceToProject(
        data?.resources_id,
        data?.engaged_from,
        data?.engaged_to
      );
    } else if (selectedAction === "edit") {
      updateProjectResource(
        data?.resources_id,
        data?.engaged_from,
        data?.engaged_to
      );
    } else {
      notification.error({
        message: "Error",
        description: `Error Add/Edit Resources`,
        placement: "topLeft",
      });
    }
  };

  return (
    <>
      <Card className="milestoneContainer">
        <ContentHeader
          contentTitle={"Member List"}
          // primaryButtonTitle={"Create New Checklist"}
          // primaryButtonClickFunction={showModal}
          titleFontSize="14px"
          primaryButtonSize="small"
        />
        <div className="milestoneListHeader">
          <div>Member Search</div>
        </div>
        <Search
          placeholder="Member Search"
          onSearch={onSearch}
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          suffix={
            searchInput ? <CloseOutlined onClick={resetSearch} /> : <span />
          }
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
        />
        {searchResult && (
          <div className="resourceListHeader">
            <div>{`Search Result`}</div>
            <div>
              <Button
                type="link"
                onClick={resetSearch}
                disabled={
                  searchResult && searchResult.length > 0 ? false : true
                }
              >
                Reset Search
              </Button>
            </div>
          </div>
        )}
        {searchResult &&
          searchResult?.length > 0 &&
          searchResult.map((item, index) => {
            return <ResourceCard item={item} key={index} />;
          })}
        {isLoading && <LoadingComponent justifyCenter />}

        {projectResources && (
          <div className="resourceListHeader">
            <div>{`Added Members`}</div>
            <div>
              <Button
                type="link"
                onClick={() =>
                  deleteConfirm(currentProjectId, removeAllResources)
                }
                disabled={
                  projectResources && projectResources.length > 0 ? false : true
                }
              >
                Delete All
              </Button>
            </div>
          </div>
        )}

        {projectResources &&
          projectResources.map((item, index) => {
            return <ResourceCard item={item} existed={true} key={index} />;
          })}
        {isLoading && <LoadingComponent justifyCenter />}
      </Card>

      <Modal
        title="Confirm Resource Add"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          className="checklistForm"
          layout="vertical"
          onFinish={onFinishResourceAdd}
          form={resourceAddForm}
        >
          <Row gutter={16}>
            <Col span={0}>
              <Form.Item name="projects_id"></Form.Item>
            </Col>
            <Col span={0}>
              <Form.Item name="resources_id"></Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="engaged_from"
                label="Engaged From"
                rules={[
                  {
                    required: true,
                    message: "Please enter Engaged From date",
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY-MM-DD HH:mm:ss"}
                  showTime={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="engaged_to"
                label="Engaged To"
                rules={[
                  {
                    required: true,
                    message: "Please enter Engaged To date",
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY-MM-DD HH:mm:ss"}
                  showTime={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ResourceAddComponent;
